import clsx from "clsx";
import { MouseEventHandler } from "react";
import Icon from "../icon/Icon";

type PropsT = {
  buttons?: {
    name: string;
    onClick?: MouseEventHandler;
    label?: string;
    icon?: string;
    iconsize?: string;
    active?: string;
  }[];
};

const MultiButton = (props: PropsT) => {
  const { buttons } = props;

  return (
    <nav className="MultiButton">
      <ul className="flex h-8 items-center text-sm">
        {buttons && buttons.length > 0
          ? buttons.map((butt, idx) => {
              return (
                <li className="" key={`${idx}-${butt.name}`}>
                  <button
                    {...butt}
                    onClick={butt?.onClick}
                    className={clsx(
                      "group -mx-px flex h-8 items-center justify-center border border-gray-200",
                      {
                        "bg-gray-100 hover:bg-gray-100": butt.active === "true",
                        "bg-white hover:bg-gray-100": butt.active === "false",

                        // First item
                        "rounded-l-full px-3": idx === 0,

                        // Last item
                        "rounded-r-full px-3": idx === buttons.length - 1,

                        // Middle items
                        "px-3": !(idx === 0 || idx === buttons.length - 1),
                      }
                    )}
                  >
                    {butt.icon && (
                      <Icon icon={butt.icon} size={butt?.iconsize} />
                    )}

                    {butt.label && (
                      <label className="text-sm text-gray-500 hover:text-gray-900">
                        {butt.label}
                      </label>
                    )}

                    <span className="sr-only">{butt.name}</span>
                  </button>
                </li>
              );
            })
          : null}
      </ul>
    </nav>
  );
};

export default MultiButton;
