import clsx from "clsx";
import { ContextMenu, Icon } from "..";
import { useButtonStyles } from "./helper";

type PropsT = {
  label?: string;
  size?: "xs" | "sm" | "base" | "lg" | "xl";
  menuItems?: ItemT[];
};

const MoreActions = (props: PropsT) => {
  const { label = "More actions", menuItems, size } = props;

  const { buttonSize, textColor, textStyle, backgroundColor, borderColor } =
    useButtonStyles({ size, label, color: "white", outline: true });

  return (
    <ContextMenu items={menuItems} position="bottom-end">
      <div
        className={clsx(
          "group relative inline-flex items-center whitespace-nowrap transition-colors duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:pointer-events-none",
          {
            [buttonSize]: buttonSize,
            [borderColor]: borderColor,
            [backgroundColor]: backgroundColor,
          }
        )}
        data-testid="more-actions-button"
      >
        <span className={`${textColor} ${textStyle}`}>{label}</span>

        <Icon icon={"caret-down"} iconStyle="ml-2 fill-gray-900" size="16" />
      </div>
    </ContextMenu>
  );
};

export default MoreActions;
