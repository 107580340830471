import { Menu, Transition } from "@headlessui/react";
import { useRouter } from "next/router";
import { useState } from "react";
import { ContactModal, Icon, Uploader } from "..";
import { createInvoice } from "../../../../apis/invoices";

type PropsT = {
  justIcon?: boolean;
  open: boolean;
  setOpen: (val: boolean) => void;
  setCloseMobile?: (val: boolean) => void;
};

export default function CreateButton(props: PropsT) {
  const { justIcon, open, setOpen, setCloseMobile } = props;
  const router = useRouter();

  const [triggerUploading, setTriggerUploading] = useState(false);

  const triggerUploaderFunc = () => {
    setTriggerUploading(true);
    setTimeout(() => {
      setTriggerUploading(false);
    }, 2000);
  };

  const handleGenerateInvoice = async () => {
    const createRequest = await createInvoice();

    if (createRequest && createRequest.invoice) {
      setCloseMobile?.(true);
      router.push(
        `/invoices/create/${createRequest.invoice.unique_identifier}`
      );
    }
  };

  return (
    <>
      <Menu as="div" className="relative">
        {({ open }) => (
          <>
            {justIcon ? (
              <Menu.Button
                className="flex h-[34px] w-[34px] items-center justify-center rounded-full bg-gradient-1 hover:bg-gradient-2 focus-visible:outline focus-visible:outline-offset-1 focus-visible:outline-primary-500"
                role="button"
                aria-label="menu-button"
              >
                <Icon icon="plus" iconStyle="fill-white" size="14" />
              </Menu.Button>
            ) : (
              <Menu.Button
                className="inline-flex h-[41px] w-full items-center justify-center whitespace-nowrap rounded-full border-transparent bg-gradient-2 px-6 text-sm font-medium text-white hover:bg-gradient-1 focus:outline-none md:mt-0 md:w-fit"
                role="button"
              >
                <Icon icon="plus" size="16" iconStyle="fill-white mr-2" />
                Add new
              </Menu.Button>
            )}

            <Transition
              show={open}
              as="div"
              className={
                justIcon
                  ? "absolute inset-x-11 -top-2 z-10"
                  : "absolute z-10 md:-left-28"
              }
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="mt-2 w-60 rounded-lg border border-gray-200 bg-white shadow-lg focus:outline-none">
                <div className="p-2">
                  <Menu.Item>
                    <span
                      onClick={handleGenerateInvoice}
                      className="inline-flex w-full cursor-pointer items-center rounded-lg p-3 text-sm font-medium text-gray-900 hover:bg-gray-100"
                    >
                      <Icon
                        icon="pencil-simple"
                        iconStyle="fill-gray-900 mr-3"
                        size="20"
                      />
                      Create new invoice
                    </span>
                  </Menu.Item>
                  <Menu.Item>
                    <span
                      onClick={triggerUploaderFunc}
                      className="inline-flex w-full cursor-pointer items-center rounded-lg p-3 text-sm font-medium text-gray-900 hover:bg-gray-100"
                    >
                      <Icon
                        icon="upload-simple"
                        iconStyle="fill-gray-900 mr-3"
                        size="20"
                      />
                      Upload invoice
                    </span>
                  </Menu.Item>
                  <Menu.Item>
                    <span
                      onClick={() => {
                        setOpen(true);
                      }}
                      className="inline-flex w-full cursor-pointer items-center rounded-lg p-3 text-sm font-medium text-gray-900 hover:bg-gray-100"
                      data-testid="add-contact-button"
                    >
                      <Icon
                        icon="address-book"
                        iconStyle="fill-gray-900 mr-3"
                        size="20"
                      />
                      Add a contact
                    </span>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>

      <ContactModal open={open} setOpen={() => setOpen(false)} />

      {/* This is really stupid but just a way so that uploader status bar stays on screen in mobile and desktop lol */}
      <div className="absolute inset-x-0 ml-0 w-[85vw] md:top-40 md:ml-80">
        <Uploader
          type="invoice"
          trigger={triggerUploading}
          id="navbar"
          hidden={true}
          onComplete={() => setCloseMobile?.(true)}
        />
      </div>
    </>
  );
}
