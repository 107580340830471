import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Icon } from "../../";
import Calendar from "./calendar";

type PropsT = {
  id?: string;
  wide?: boolean;
  clearable?: boolean;
  onChange?: (newDate: string) => void;
  value?: string;
  startValue?: string;
  error?: string;
  name?: string;
  label?: string;
  disabled?: boolean;
};

export default function DatePicker(props: PropsT) {
  const {
    name = "",
    id,
    label,
    value,
    wide,
    clearable,
    onChange,
    error,
    disabled = false,
  } = props;
  const elementRef = useRef<HTMLDivElement>(null);
  const idForInput = id || name || label;
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDay, setSelectedDay] = useState<Date | undefined>(
    value ? new Date(value) : undefined
  );

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (!elementRef?.current?.contains(event.target as Element)) {
        setShowCalendar(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const clearInput = () => {
    if (onChange) {
      onChange("");
      setSelectedDay(undefined);
    }
  };

  return (
    <div
      className={clsx("DatePicker relative w-full", {
        "w-full md:w-80": !wide,
      })}
      data-testid="date-input"
      ref={elementRef}
    >
      <div className="relative w-full">
        <input
          id={idForInput}
          name={name}
          type="text"
          readOnly
          className={`peer z-10 block w-full appearance-none overflow-ellipsis rounded-lg border border-gray-300 bg-white pb-2.5 pl-[15px] pr-12 pt-4 text-sm text-gray-900 placeholder:text-transparent autofill:text-sm autofill:text-gray-900 focus:border-gray-900 focus:pb-2.5 focus:pt-4 focus:outline-none focus:ring-0 ${
            !error || "!border-orange-600 !bg-orange-100"
          }`}
          placeholder=" "
          value={
            selectedDay?.toLocaleDateString("en-CA", {
              year: "numeric",
              month: "long",
              day: "numeric",
            }) || ""
          }
          onClick={() => {
            setShowCalendar((s) => !s);
          }}
          disabled={disabled}
          aria-invalid={!!error}
          aria-describedby="datepicker-error"
        />

        <label
          htmlFor={idForInput}
          className={clsx(
            "absolute left-4 top-3.5 z-10 cursor-text text-sm text-gray-500 duration-300",
            "origin-[0] -translate-y-3 scale-75 transform peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-autofill:-translate-y-3 peer-autofill:scale-75",
            "transition-all"
          )}
        >
          {label}
        </label>

        {value && clearable && (
          <div
            onClick={clearInput}
            className="absolute inset-y-0 right-8 flex cursor-pointer items-center pr-2"
          >
            <Icon icon="x-circle" size="20" iconStyle="fill-gray-900" />
          </div>
        )}

        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <Icon icon="calendar-blank" iconStyle="fill-gray-900" size="20" />
        </div>
      </div>

      {error && (
        <div className="mt-1 flex flex-row items-center text-sm font-semibold text-orange-600">
          <Icon
            icon="warning-fill"
            size="16"
            iconStyle="fill-orange-600 mr-2"
          />
          {error}
        </div>
      )}

      <Calendar
        open={showCalendar}
        value={selectedDay}
        onSelect={(selectedDay: Date) => {
          setShowCalendar(false);
          setSelectedDay(selectedDay);
          onChange && onChange(selectedDay.toString());
        }}
      />
    </div>
  );
}
