import { useEffect, useMemo, useState } from "react";
import { syncInvoice } from "../../../../apis/invoices";
import { useAuth } from "../../../../state/AuthContexts";
import {
  Button,
  CurrencyTextInput,
  DatePicker,
  Modal,
  SelectInput,
} from "../../../shared/components";
import SyncSuccessModal from "./syncSuccessModal";

type PropsT = {
  open: boolean;
  setOpen: (open: boolean) => void;
  invoice: InvoiceType;
  isBill: boolean;
  refresh?: () => void;
  taxRates: TaxRatesT[];
  chartOfAccounts: ERPAccountT[];
};

export default function SyncERPModal(props: PropsT) {
  const { open, setOpen, invoice, isBill, refresh, taxRates, chartOfAccounts } =
    props;
  const { company } = useAuth();
  const entity = company?.Connections?.[0]?.OrganizationalEntities?.[0];

  const [syncing, setSyncing] = useState(false);
  const [startedSync, setStartedSync] = useState(false);

  const [taxRate, setTaxRate] = useState({ label: "", value: "" });
  const [account, setAccount] = useState({ label: "", value: "" });
  const [postDate, setPostDate] = useState(invoice.issue_date || new Date());
  const [taxRateError, setTaxRateError] = useState("");
  const [accountError, setAccountError] = useState("");
  const [postedDateError, setPostedDateError] = useState("");

  useEffect(() => {
    if (entity) {
      if (isBill) {
        const companyBillsAccount = chartOfAccounts?.find(
          (acc) => acc?.erp_id === entity?.default_erp_bills_account
        );
        const companyTaxRate = taxRates?.find(
          (acc) => acc?.id === entity?.default_erp_bills_tax_rate
        );

        if (companyBillsAccount) {
          setAccount({
            label: companyBillsAccount?.name,
            value: companyBillsAccount?.erp_id,
          });
        }

        if (companyTaxRate) {
          setTaxRate({
            label: companyTaxRate?.name,
            value: companyTaxRate?.id,
          });
        }
      } else {
        const companyRevenueAccount = chartOfAccounts?.find(
          (acc) => acc?.erp_id === entity?.default_erp_revenue_account
        );
        const companyRevenueTaxRate = taxRates?.find(
          (acc) => acc?.id === entity?.default_erp_revenue_tax_rate
        );

        if (companyRevenueAccount) {
          setAccount({
            label: companyRevenueAccount?.name,
            value: companyRevenueAccount?.erp_id,
          });
        }

        if (companyRevenueTaxRate) {
          setTaxRate({
            label: companyRevenueTaxRate?.name,
            value: companyRevenueTaxRate?.id,
          });
        }
      }
    }
  }, [taxRates, chartOfAccounts, entity, isBill]);

  const allTaxRates = useMemo(() => {
    const validTaxRates =
      taxRates?.filter((x) => {
        if (!x.components) {
          return false;
        }

        for (const component of x.components) {
          if (
            (isBill && !component.isPurchasesTax) ||
            (!isBill && !component.isSalesTax)
          ) {
            continue;
          }
          return isBill ? component.isPurchasesTax : component.isSalesTax;
        }
        return false;
      }) || [];

    return (
      validTaxRates.map((tr) => {
        const rate =
          tr.components && tr.components[0]
            ? tr.components[0]?.rate > 1
              ? tr.components[0].rate
              : tr.components[0].rate * 100
            : 0;

        return {
          label: `${tr.name} - (${rate}%)`,
          value: tr.id,
        };
      }) || []
    );
  }, [taxRates, isBill]);

  const taxPercentage = useMemo(() => {
    const rate = taxRates?.find(
      (tr) => tr?.id === taxRate.value
    )?.effectiveTaxRate;
    if (rate && rate > 1) {
      return rate;
    } else if (rate) {
      return rate * 100;
    } else {
      return 0;
    }
  }, [taxRates, taxRate.value]);

  const allAccounts = isBill
    ? chartOfAccounts
        ?.filter((account) => account?.section === "expense")
        .map((acc) => {
          return { label: acc?.name, value: acc?.erp_id };
        }) || []
    : chartOfAccounts
        ?.filter((account) => account?.section === "revenue")
        .map((acc) => {
          return { label: acc?.name, value: acc?.erp_id };
        }) || [];

  const totalWTaxRate = !taxPercentage
    ? invoice.totals.total
    : parseFloat((invoice.totals.total / (1 + taxPercentage / 100)).toFixed(2));
  const taxTotal = !taxPercentage
    ? invoice.totals.tax
    : ((invoice.totals.total * 100 - totalWTaxRate * 100) / 100).toFixed(2);

  const handleSync = async (
    account: { value: string; label: string },
    tax: { value: string; label: string },
    postedDate: string | Date
  ) => {
    setSyncing(true);
    setAccountError("");
    setTaxRateError("");
    setPostedDateError("");
    if (!account.value || !tax.value || !postedDate) {
      // We need something
      setAccountError(!account.value ? "Account is required" : "");
      setTaxRateError(!tax.value ? "Tax rate is required" : "");
      setPostedDateError(!postedDate ? "Posted date is required" : "");
      setSyncing(false);
      return;
    }

    syncInvoice({
      id: invoice?.id,
      account,
      tax,
      postedDate,
    })
      .then((res) => {
        if (res && res.success) {
          setStartedSync(true);
        }
      })
      .finally(() => setSyncing(false));
  };

  if (startedSync && invoice.unique_identifier) {
    return (
      <SyncSuccessModal
        open={startedSync}
        id={invoice.unique_identifier}
        setOpen={() => {
          setStartedSync(false);
          setOpen(false);
        }}
        refresh={refresh}
        invoice={invoice}
        isBill={isBill}
      />
    );
  } else {
    return (
      <Modal open={open} setOpen={setOpen} title="Sync to ERP">
        <div
          className="flex flex-col p-3 md:w-[692px] md:p-5"
          data-testid="sync-erp-modal"
        >
          <div className="flex flex-col gap-3 md:flex-row">
            <div className="flex flex-1 flex-col gap-3">
              <SelectInput
                label="Account"
                placeholder="Select an account..."
                options={allAccounts}
                value={allAccounts.find((tr) => account.value === tr.value)}
                onChange={(val) => {
                  setAccount(val);
                }}
                required
                showRequired
                error={accountError}
              />
              <SelectInput
                label="Tax"
                placeholder="Select a tax rate..."
                options={allTaxRates}
                value={allTaxRates.find((tr) => taxRate.value === tr.value)}
                onChange={(val) => {
                  setTaxRate(val);
                }}
                required
                showRequired
                error={taxRateError}
              />
              <DatePicker
                wide
                label="Post date"
                value={postDate.toString()}
                onChange={setPostDate}
                error={postedDateError}
              />
            </div>
            <div className="flex flex-1 flex-col gap-3">
              <CurrencyTextInput
                label="Amount"
                id="invoice-amount"
                value={totalWTaxRate}
                disabled
              />
              <CurrencyTextInput
                label="Amount"
                id="tax-amount"
                value={taxTotal}
                disabled
              />
            </div>
          </div>
          <div className="flex flex-row justify-end space-x-3.5 pt-6">
            <Button
              onClick={() => setOpen(false)}
              label="Cancel"
              color="white"
              outline
            />

            <Button
              onClick={() => handleSync(account, taxRate, postDate)}
              label="Sync"
              loading={syncing}
            />
          </div>
        </div>
      </Modal>
    );
  }
}
