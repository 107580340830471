import { Dialog, Transition } from "@headlessui/react";
import { Fragment, ReactNode, useCallback, useRef } from "react";
import Button from "../buttons/button";

type PropsT = {
  open: boolean;
  setOpen: (value: boolean) => void;
  title?: string;
  children?: ReactNode;
  hideCloseButton?: boolean;
  disableOverlayClose?: boolean;
  "data-testid"?: string;
};

export default function Modal(props: PropsT) {
  const {
    open,
    setOpen,
    title,
    children,
    hideCloseButton,
    disableOverlayClose = false,
    "data-testid": dataTestId = "modal",
  } = props;

  const cancelButtonRef = useRef(null);
  const dialogRef = useRef(null);

  const handleClose = useCallback(
    (value: boolean) => {
      if (disableOverlayClose) return false;

      setOpen(value);
      return true;
    },
    [setOpen, disableOverlayClose]
  );

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        ref={dialogRef}
        as="div"
        className="relative z-30 pt-10"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div
          className="fixed inset-0 z-10 w-screen overflow-y-auto"
          data-testid={dataTestId}
        >
          <div className="flex min-h-full items-end justify-center p-4 sm:items-center">
            <Transition.Child
              as={Fragment}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white shadow-xl transition-all duration-300 md:m-8">
                {title ? (
                  <Dialog.Title className="border-b border-gray-200 pb-2 pl-3 pr-14 pt-3 text-left font-gilroy text-lg font-bold leading-6 text-black sm:h-[57px] sm:whitespace-nowrap sm:py-4 sm:pl-4 sm:text-xl md:pl-5">
                    {title}
                  </Dialog.Title>
                ) : (
                  <div className="h-8" />
                )}

                {!hideCloseButton && (
                  <div className="absolute right-2 top-1.5 sm:right-4 sm:top-3.5">
                    <Button
                      icon="x"
                      data-testid="modal-close-button"
                      onClick={() => setOpen(false)}
                      customIconSize="20"
                      color="transparent"
                    />
                  </div>
                )}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
