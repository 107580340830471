import * as yup from "yup";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const bankAccountSchema = yup.object().shape({
  account_number: yup
    .string()
    .matches(/^\d{7,12}$/, "Account number should only contain numbers")
    .min(7, "Account number should be 7-12 digits")
    .max(12, "Account number should be 7-12 digits")
    .required("Please enter your account number"),
  transit_number: yup
    .string()
    .matches(/^\d{5}$/, "Transit number should only contain numbers")
    .length(5, "Transit number should be 5 digits")
    .required("Please enter your transit number"),
  institution_number: yup
    .string()
    .matches(/^\d{3}$/, "Institution number should only contain numbers")
    .length(3, "Institution number should be 3 digits")
    .required("Please enter your institution number"),
  email: yup
    .string()
    .lowercase()
    .matches(emailRegex, "Please enter a valid email address")
    .nullable()
    .transform((value, originalValue) =>
      originalValue.trim() !== "" ? originalValue : null
    ),
  first_name: yup.string().required("Please enter your first name"),
  last_name: yup.string().required("Please enter your last name"),
});

export const creditCardSchema = yup.object({
  cardNumber: yup
    .string()
    .label("Card number")
    .min(18, "Card number needs to be at minimum 15 digits")
    .max(19, "Card number needs to be at most 16 digits")
    .required(),
  cvc: yup.string().label("CVC").min(3).max(4).required(),
  cardHolderName: yup.string().label("Name on card").required(),
  cardExpiryMonth: yup
    .number()
    .label("Expiry month")
    .transform((val, originalVal) => {
      return parseInt(originalVal);
    })
    .min(1)
    .max(12)
    .required(),
  cardExpiryYear: yup
    .number()
    .label("Expiry year")
    .transform((val, originalVal) => {
      return parseInt(originalVal);
    })
    .min(23)
    .required(),
  cardHolderPostalCode: yup.string().label("Postal code").min(1).required(),
  cardHolderAddress: yup.string().label("Address").min(1).required(),
});
