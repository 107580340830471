import { useRouter } from "next/router";
import { ReactNode, useEffect, useState } from "react";
import { useAuth } from "../../../../state";
import {
  SelectEntitiesDropdownHeader,
  SetupIncompleteERPModal,
} from "../../../features/integrations/components";

import { Toaster } from "../toast/toaster";
import Navbar from "./navBar";

const hideNavBarURLs = [
  "/signup",
  "/signup/verify",
  "/login",
  "/register",
  "/forgot",
];

export default function Layout({ children }: { children: ReactNode }) {
  const router = useRouter();
  const { user } = useAuth();
  const hideNavBar = hideNavBarURLs.some((url) => router.asPath.includes(url));
  const [hideNav, setHideNav] = useState(true);

  useEffect(() => {
    if (
      localStorage &&
      JSON.parse(localStorage.getItem("q-state") || "{}")?.token
    ) {
      setHideNav(false);
    } else {
      setHideNav(true);
    }
  }, []);

  useEffect(() => {
    if (user?.id) setHideNav(false);
  }, [user?.id]);

  useEffect(() => {
    // this guy listens to see if we logout or log in, and reacts accordingly for each tab.
    const onStorageChange = async (event: StorageEvent) => {
      if (event.key === "q-state") {
        // If the user logs out, reload tab to be prompted to /login page
        if (event.oldValue && !event.newValue) {
          await router.replace("/logout");
          router.reload();
        }
        // If user logs in, lets push to the invoices page
        if (!event.oldValue && event.newValue) {
          await router.replace("/invoices");
          router.reload();
        }
      }
    };

    window.addEventListener("storage", onStorageChange);

    return () => {
      window.removeEventListener("storage", onStorageChange);
    };
  }, [router]);

  return (
    <div className={`Layout h-[90vh] md:h-screen`}>
      {hideNavBar || hideNav ? null : <Navbar />}
      <SelectEntitiesDropdownHeader />
      <div className="container md:max-w-7xl">
        <main
          className={`pt-[5rem] md:py-[2rem]${hideNavBar || hideNav ? "" : " md:pl-[60px]"}`}
        >
          {children}
          <Toaster />
          <SetupIncompleteERPModal />
        </main>
      </div>
    </div>
  );
}
