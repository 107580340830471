export function computeQuicklyCustomerState(
  defaultValue: string,
  customers: ContactType[],
  id?: string | null,
  pushcommunicationid?: string | null,
  state?: ERPProcessingStateT
): { label: string; value: string } | undefined {
  if (state && !id && ["in-progress", "failed"].includes(state)) {
    return {
      label: defaultValue,
      value: id || "",
    };
  }

  if (id) {
    const foundAccount = customers.find((x) => x.erp_id === id);

    if (!foundAccount) {
      if (pushcommunicationid) {
        return {
          label: defaultValue,
          value: id,
        };
      }
      return undefined;
    }

    return {
      label: foundAccount.company_name,
      value: foundAccount.erp_id || "",
    };
  }

  return undefined;
}
