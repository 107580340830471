import { useState } from "react";
import { useAuth } from "../../../../state/AuthContexts";
import Button from "../buttons/button";
import { useToast } from "../toast/useToast";
import Uploader from "../uploader";
import Avatar from "./avatar";

type PropsT = {
  user?: UserType;
  company?: CompanyType;
  title: string;
  description: string;
  notification?: number;
  status?: boolean;
};

export default function UploadAvatar(props: PropsT) {
  const { title, description, company, user: propsUser } = props;
  const { updateUser, updateCompany, user, getCompany } = useAuth();
  const { toast } = useToast();

  const [removing, setRemoving] = useState(false);
  const [tempCompany, setTempCompany] = useState<CompanyType>();

  const finishedUpload = async (urls: { success: boolean; url: string }[]) => {
    if (user) {
      if (propsUser) {
        await updateUser(
          user.token,
          propsUser?.first_name,
          propsUser?.last_name,
          propsUser?.email,
          propsUser?.phone,
          urls[0]?.url
        );
      } else if (company) {
        setTempCompany({ ...company, logo_url: urls[0]?.url });
        await updateCompany({
          logo_url: urls[0]?.url,
        });
        getCompany(user.token);
      }
      toast({
        variant: "success",
        title: "Photo updated successfully!",
      });
    }
  };

  const handleRemoveAvatar = async () => {
    if (user) {
      setRemoving(true);
      if (propsUser) {
        await updateUser(
          user.token,
          propsUser?.first_name,
          propsUser?.last_name,
          propsUser?.email,
          propsUser?.phone,
          ""
        );
      } else if (company) {
        await updateCompany({ logo_url: "" });
        getCompany(user.token);
      }
      setRemoving(false);
    }
  };

  return (
    <div className="max-w-sm" data-testid="uploadAvatar">
      <div className="flex flex-row">
        {company && (company?.logo_url || tempCompany?.logo_url) && (
          <div className="relative mr-6 flex w-24 flex-shrink-0">
            <div
              className={`h-full w-full bg-contain bg-no-repeat`}
              role="img"
              aria-label="Company Logo"
              style={{
                backgroundImage: `url('${
                  company?.logo_url || tempCompany?.logo_url
                }')`,
              }}
            />
          </div>
        )}

        {propsUser && <Avatar user={propsUser} />}
        <div>
          <div className="block text-base font-medium text-gray-900">
            {title}
          </div>
          <div className="mb-2 text-xs text-gray-500">{description}</div>
          {((company && company?.logo_url) ||
            (user?.avatar_url && propsUser)) && (
            <Button
              size="xs"
              label="Remove"
              onClick={handleRemoveAvatar}
              color="dark"
              loading={removing}
              outline
            />
          )}
        </div>
      </div>
      <div className="mt-4" data-testid="uploader">
        <Uploader
          type={propsUser ? "user" : "company"}
          onComplete={finishedUpload}
          id="avatar"
        />
      </div>
    </div>
  );
}
