import { Combobox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment, useCallback, useEffect, useState } from "react";
import { ContactModal, Icon, TextButton, TextInput } from "..";
import { searchCompany } from "../../../../apis/company";
import { getCompanyContacts } from "../../../../apis/contacts";
import { useAuth } from "../../../../state";
import { getCompanyName, isCompany, isContact } from "../../helpers";
import { emailSchema, validateAndSetErrors } from "../../schemas";

type PropsT = {
  onSelect: (customer?: CustomerT) => void; // this is to pass the input values to calling parent
  customer?: CustomerT; // this is to be passed to component when loading page
  error?: string;
  wide?: boolean;
};

const ContactSearch = (props: PropsT) => {
  const { onSelect, error, customer, wide } = props;
  const { company } = useAuth();

  const [selectedContact, setSelectedContact] = useState<CustomerT | undefined>(
    customer
  );

  const [openContactModal, setOpenContactModal] = useState(false);
  const [showInputs, setShowInputs] = useState(false);

  const [query, setQuery] = useState("");
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(false);

  const [allContacts, setAllContacts] = useState<ContactType[]>([]);
  const [qNetwork, setQNetwork] = useState<CompanyType[]>([]);
  const [contactHasEmail, setContactHasEmail] = useState(true);
  const [emailError, setEmailError] = useState<ErrorT>();

  const getContacts = useCallback(
    async (sanitizedQuery?: string) => {
      setLoadingContacts(true);
      getCompanyContacts({
        offset: 0,
        limit: 20,
        query: sanitizedQuery || "",
      })
        .then((req) => {
          if (!req.success) throw Error(req.message);
          setAllContacts(
            req.contacts.filter(
              (c) =>
                c.company_id !== c.to_company_id && c.email !== company?.email
            )
          );
        })
        .catch((err) => console.error(err))
        .finally(() => setLoadingContacts(false));
    },
    [company?.email]
  );

  useEffect(() => {
    getContacts();
  }, [getContacts]);

  useEffect(() => {
    if (customer) {
      setShowInputs(true);
      if (isCompany(customer)) {
        setQuery(customer.name);
      } else if (isContact(customer)) {
        setQuery(customer.company_name);
      }
    }
  }, [customer]);

  const handleSearch = async (queryString: string) => {
    setQuery(queryString);

    const sanitizedQuery = queryString.toLowerCase().trim();

    if (sanitizedQuery.length < 3) {
      getContacts(sanitizedQuery);
    } else {
      setLoadingContacts(true);
      setLoadingCompanies(true);

      getContacts(sanitizedQuery);

      searchCompany({ query: sanitizedQuery })
        .then((res) =>
          res.matches.filter((qCompany) => qCompany?.id !== company?.id)
        )
        .then((matches) => {
          setQNetwork(matches);
        })
        .catch((err) => console.error(err))
        .finally(() => setLoadingCompanies(false));
    }
  };

  const handleSelect = async (customer: CustomerT) => {
    setEmailError({});
    setQuery(getCompanyName(customer) || "");
    onSelect(customer);
    setSelectedContact(customer);
    setShowInputs(true);
    verifyEmail(customer);
  };

  const verifyEmail = async (newCustomer: CustomerT) => {
    if (isCompany(newCustomer)) {
      setContactHasEmail(Boolean(newCustomer.email));
    } else {
      const email = newCustomer?.email;
      const invalidEmail = await validateAndSetErrors(
        emailSchema,
        { email },
        setEmailError
      );

      if (invalidEmail) {
        setContactHasEmail(false);
      }

      if (!invalidEmail) {
        onSelect(newCustomer);
        setEmailError({});
        setContactHasEmail(true);
      }
    }
  };

  const handleSaveContact = async () => {
    await verifyEmail(selectedContact as CustomerT);
  };

  return (
    <>
      <Combobox
        as="div"
        onChange={(customer: CustomerT) => {
          handleSelect(customer);
        }}
        className={clsx("ContactSearch relative w-full max-w-full", {
          "w-full md:w-80": !wide,
        })}
        nullable
      >
        <>
          <div className="pointer-events-none absolute left-0 top-3.5 pl-4">
            <Icon icon="magnifying-glass" size="20" iconStyle="fill-gray-500" />
          </div>

          <Combobox.Input
            name="comboboxInput"
            placeholder=" "
            type="text"
            autoComplete="off"
            value={query}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
            className={clsx(
              "z-10 block w-full appearance-none rounded-lg border border-gray-300 bg-white px-[15px] pb-2.5 pl-10 pr-8 pt-4", // input field
              "peer placeholder:text-transparent focus:border-gray-900 focus:pb-2.5 focus:pt-4 focus:outline-none focus:ring-0", // input state
              "overflow-ellipsis text-sm text-gray-900 autofill:text-sm autofill:text-gray-900", // text
              {
                "!border-orange-600 !bg-orange-100": error,
              }
            )}
          />

          <Combobox.Label
            className={clsx(
              "absolute left-10 top-3.5 cursor-text text-sm text-gray-500",
              "origin-[0] -translate-y-3 scale-75 transform duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-autofill:-translate-y-3 peer-autofill:scale-75 peer-focus:-translate-y-3 peer-focus:scale-75",
              "transition-all"
            )}
          >
            {selectedContact ? "Customer name:" : "Search contacts..."}
          </Combobox.Label>

          <Combobox.Button
            data-testid="contact-search-trigger"
            className="absolute top-0 z-0 h-12 w-full bg-transparent hover:cursor-text"
          ></Combobox.Button>

          {(query.length > 0 || Boolean(customer)) && (
            <div
              className="absolute right-0 top-3.5 cursor-pointer pr-3"
              data-testid="clear-button"
              onClick={() => {
                setSelectedContact(undefined);
                setQuery("");
                onSelect(undefined);
                getContacts();
                setQNetwork([]);
              }}
            >
              <Icon
                icon="x-circle"
                size="20"
                iconStyle="fill-gray-400 hover:fill-gray-900"
              />
            </div>
          )}

          {error && (
            <div className="mt-1 flex flex-row items-center text-sm font-semibold text-orange-600">
              <Icon
                icon="warning-fill"
                size="16"
                iconStyle="fill-orange-600 mr-2"
              />
              {error}
            </div>
          )}

          <Transition
            enter="transition duration-30 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-300 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            className="absolute z-20 w-full"
          >
            <Combobox.Options className="absolute z-10 mt-2 max-h-80 min-h-5 w-full overflow-y-auto rounded-lg border border-gray-200 bg-white p-4 shadow-lg">
              <li data-testid="contact-search-contacts" key="contacts">
                <div className="flex justify-between">
                  <h2 className="bg-white text-sm font-semibold text-gray-900">
                    Contacts
                  </h2>
                  <TextButton
                    label={selectedContact ? "Edit contact" : "Add new"}
                    onClick={() => setOpenContactModal(true)}
                    icon={selectedContact ? "pencil-simple" : "plus"}
                  />
                </div>

                {loadingContacts ? (
                  <div className="my-4 text-center text-sm text-gray-500">
                    Loading...
                  </div>
                ) : (
                  <ul className="my-4 space-y-2">
                    {allContacts.length > 0 ? (
                      allContacts.slice(0, 5).map((contact) => (
                        <Combobox.Option
                          key={contact.id}
                          value={contact}
                          as={Fragment}
                        >
                          {({ active }) => (
                            <li
                              className={clsx(
                                "flex cursor-pointer select-none flex-col rounded-lg bg-gray-50 px-3 py-2 hover:bg-gray-100",
                                active && "!bg-gray-100"
                              )}
                            >
                              <div className="flex justify-between ">
                                <span className="text-sm text-gray-900">
                                  {contact.company_name}
                                </span>
                                <Icon
                                  icon="arrow-up-right"
                                  iconStyle="fill-gray-900"
                                  size="14"
                                />
                              </div>
                              <span className="text-xs text-gray-500">
                                {contact.email}
                              </span>
                            </li>
                          )}
                        </Combobox.Option>
                      ))
                    ) : (
                      <div className="text-center text-sm text-gray-500">
                        No contacts found
                      </div>
                    )}
                  </ul>
                )}
              </li>

              <li key="qnetwork">
                <h2 className="mt-4 bg-white text-sm font-semibold text-gray-900">
                  {"Quickly's network"}
                </h2>

                {loadingCompanies ? (
                  <div className="mt-3 text-center text-sm text-gray-500">
                    Loading...
                  </div>
                ) : (
                  <ul className="mt-3 space-y-2">
                    {qNetwork.length > 0 ? (
                      qNetwork.slice(0, 5).map((item: CompanyType) => (
                        <Combobox.Option
                          key={item.id}
                          value={item}
                          as={Fragment}
                        >
                          {({ active }) => (
                            <li
                              className={clsx(
                                "flex cursor-pointer select-none items-center justify-between rounded-lg bg-gray-50 px-3 py-2 hover:bg-gray-100",
                                active && "!bg-gray-100"
                              )}
                            >
                              <div className="flex items-center space-x-2">
                                <Icon
                                  icon="globe-simple"
                                  iconStyle="fill-gray-400"
                                  size="16"
                                />
                                <span className="text-sm text-gray-900">
                                  {item.name}
                                </span>
                              </div>
                              <Icon
                                icon="arrow-up-right"
                                iconStyle="fill-gray-900"
                                size="14"
                              />
                            </li>
                          )}
                        </Combobox.Option>
                      ))
                    ) : query.length >= 3 ? (
                      <div className="text-center text-sm text-gray-500">
                        No companies found
                      </div>
                    ) : (
                      <div className="text-center text-sm text-gray-500">
                        Type to search companies in the Quickly Network
                      </div>
                    )}
                  </ul>
                )}
              </li>
            </Combobox.Options>
          </Transition>
          {selectedContact && showInputs && (
            <div className="mt-3 space-y-3">
              <TextInput
                label="Email"
                required
                value={
                  contactHasEmail
                    ? selectedContact?.email || ""
                    : (selectedContact as ContactType)?.email || ""
                }
                wide={wide}
                onChange={(event) => {
                  setSelectedContact({
                    ...selectedContact,
                    email: event.target.value,
                  });
                  setEmailError({ email: "" });
                }}
                disabled={contactHasEmail}
                error={emailError?.email}
                onBlur={async () => {
                  handleSaveContact();
                }}
              />

              {selectedContact &&
                isContact(selectedContact) &&
                selectedContact.last_name &&
                selectedContact.first_name && (
                  <TextInput
                    disabled
                    value={
                      selectedContact.first_name +
                      " " +
                      selectedContact.last_name
                    }
                    label="Full name"
                    wide={wide}
                  />
                )}
              {selectedContact.phone && (
                <TextInput
                  disabled
                  value={selectedContact.phone}
                  label="Phone"
                  wide={wide}
                />
              )}
            </div>
          )}
        </>
      </Combobox>

      <ContactModal
        open={openContactModal}
        contact={isContact(selectedContact) ? selectedContact : undefined}
        setContact={(newContact: ContactType) => onSelect(newContact)}
        setOpen={() => {
          setOpenContactModal(false);
        }}
      />
    </>
  );
};

export default ContactSearch;
