import { AssetTypeE } from "../../src/features/auth/types";
import { fetchWithAuth } from "../interceptor";
import { APIResponsegetUploadLink } from "./types";

export const archiveAsset = async (asset: AssetType): Promise<APIResponse> => {
  return fetchWithAuth(`/company/assets/${asset.id}`, { method: "DELETE" });
};

export const getUploadLink = async (props: {
  type: string;
  fileType: string;
  fileName: string;
  typeOfAsset: string;
  invoiceId?: number | null;
}): Promise<APIResponsegetUploadLink> => {
  const { type, fileType, fileName, typeOfAsset, invoiceId } = props;
  const name = fileName.replace("#", "");
  const url = `/company/assets/create/${type}?fileName=${name}&fileType=${fileType}&typeOfAsset=${typeOfAsset}${invoiceId ? `&invoiceId=${invoiceId}` : ""}`;
  return fetchWithAuth(url, { method: "GET" });
};

export const uploadAsset = async (props: {
  type: AssetTypeE;
  files: FileList;
  onComplete: () => void;
  invoiceId?: number;
}): Promise<APIResponsegetUploadLink[]> => {
  const { type, files, onComplete, invoiceId } = props;

  const uploadType =
    type === AssetTypeE.invoice || type === AssetTypeE.invoiceSupport
      ? "invoice"
      : type === AssetTypeE.companyLogo
        ? "company"
        : type === AssetTypeE.userLogo
          ? "user"
          : type === AssetTypeE.manualEPVendors
            ? "manualEpVendors"
            : type === AssetTypeE.manualEPOpenRegister
              ? "manualEpOpenRegister"
              : "companyDocument";

  const newFiles: File[] = [];

  Array.from(files || []).forEach((file) => newFiles.push(file));

  const uploadStatus: Array<{ success: boolean; url: string }> = [];
  const responses = [];

  for (const [index, file] of newFiles.entries()) {
    const getLinkReq = await getUploadLink({
      type: uploadType,
      fileType: file.type,
      fileName: file.name,
      typeOfAsset: type,
      invoiceId,
    });
    responses.push(getLinkReq);

    if (getLinkReq && getLinkReq.success) {
      // Send the files to the link
      const url = new URL(decodeURIComponent(getLinkReq.url));
      const formData = new FormData();

      Object.keys(getLinkReq.fields).forEach((key: string) => {
        formData.append(key, getLinkReq.fields[key]);
      });

      formData.append("Content-Type", file.type);
      formData.append("file", file);

      const xhr = new XMLHttpRequest();

      xhr.addEventListener("loadend", () => {
        if (xhr.readyState === 4 && xhr.status === 204) {
          uploadStatus[index] = {
            success: true,
            url: `${url.toString()}/${formData.get("key")}`,
          };
        }
      });

      xhr.open("POST", url.toString(), true);
      xhr.send(formData);

      const allFilesUploaded = uploadStatus.every((status) => status.success);

      if (allFilesUploaded) {
        onComplete();
      }
    }
  }
  return responses;
};
