import Button from "../buttons/button";

interface BottomNavBarProps {
  onSubmit: (e: React.SyntheticEvent) => void;
  loading: boolean;
  cancelLoading?: boolean;
  disabled: boolean;
  onCancel?: () => void;
}
export default function BottomNavBar(props: BottomNavBarProps) {
  const { onSubmit, loading, cancelLoading, disabled, onCancel } = props;

  return (
    <div
      className="fixed inset-x-0 bottom-0 z-10 flex items-center justify-end border-t border-gray-200 bg-white"
      data-testid="bottom-nav-bar"
    >
      <div className="container flex justify-end space-x-4 py-4 md:mx-auto md:max-w-7xl">
        <Button
          label="Cancel"
          color="dark"
          outline
          onClick={onCancel}
          loading={cancelLoading}
          disabled={disabled}
        />
        <Button
          label="Save"
          onClick={onSubmit}
          loading={loading}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
