import clsx from "clsx";
import { useRouter } from "next/router";
import Icon from "../icon/Icon";

type PropsT = {
  items: {
    label: string;
    complete: boolean;
    current: boolean;
    href: string;
  }[];
  label?: string;
};

const ProgressStepper = (props: PropsT) => {
  const { items, label } = props;
  const router = useRouter();

  return (
    <>
      {label && (
        <h1 className="font-gilroy text-2xl font-bold sm:mb-3 sm:text-center">
          {label}
        </h1>
      )}
      <nav aria-label="Breadcrumb" data-testid="ProgressStepper">
        <ol role="list" className="sm:flex sm:justify-between">
          {items.length > 0 &&
            items.map((item) => (
              <li
                onClick={() => router.push(item.href)}
                key={item.label}
                className={clsx(
                  "group flex w-full cursor-pointer flex-col pr-1",
                  {
                    "text-gray-900": item.current,
                    "text-gray-500": !item.current,
                  }
                )}
              >
                <div
                  className={clsx(
                    "rounded-md group-hover:bg-gray-900 sm:h-[2px] sm:w-full",
                    {
                      "bg-gray-900": item.current,
                      "bg-gray-200": !item.current,
                    }
                  )}
                />
                <div className="mt-2 flex flex-row space-x-1">
                  <Icon
                    size="18"
                    iconStyle={
                      item.current
                        ? "fill-gray-900 group-hover:fill-gray-900"
                        : "fill-gray-400 group-hover:fill-gray-900"
                    }
                    icon={item.complete ? "check-circle-fill" : "circle"}
                  />
                  <div
                    className={clsx(
                      "whitespace-nowrap pr-4 text-xs font-medium group-hover:text-gray-900"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.label}
                  </div>
                </div>
              </li>
            ))}
        </ol>
      </nav>
    </>
  );
};

export default ProgressStepper;
