import clsx from "clsx";
import { MouseEventHandler } from "react";
import Icon from "../icon/Icon";

export interface ITextButtonProps {
  // for all TextButtons
  "data-testid"?: string; // for unit tests
  onClick?: MouseEventHandler; // what it does
  submit?: boolean; // when used in a form to handle submit
  disabled?: boolean; // disabled state
  loading?: boolean; // loading state

  // to specify type of TextButton to render
  label?: string; // what text to show
  color?: "primary" | "green" | "orange" | "dark" | "gray" | "error";
  position?: "center" | "top";
  size?: "sm" | "base" | "lg" | "xl"; // sm or base

  // for icon
  icon?: string;
  iconLeft?: boolean;
  iconRight?: boolean;
}

export default function TextButton(props: ITextButtonProps) {
  const {
    "data-testid": dataTestId,
    onClick,
    submit = false,
    loading = false,
    disabled = false,
    label = "",
    color = "",
    size = "",
    icon = "",
    iconLeft = false,
    iconRight = false,
    position = "center",
  } = props;

  let textColor = "";
  let textStyle = "";
  let iconColor = "";
  let iconSize = "";
  let loaderSize = "";

  switch (size) {
    case "sm":
      textStyle = "font-medium text-xs";
      iconSize = "14";
      loaderSize = "h-3 w-3";
      break;
    case "base":
      textStyle = "font-medium text-sm";
      iconSize = "16";
      loaderSize = "h-4 w-4";
      break;
    case "lg":
      textStyle = "font-medium text-base";
      iconSize = "16";
      loaderSize = "h-5 w-5";
      break;
    case "xl":
      textStyle = "font-medium text-base";
      iconSize = "20";
      loaderSize = "h-5 w-5";
      break;
    default: // default to base
      textStyle = "font-medium text-sm";
      iconSize = "16";
      loaderSize = "h-4 w-4";
  }

  switch (color) {
    case "primary":
      textColor = "text-primary-700";
      iconColor = "fill-primary-700";
      break;
    case "green":
      textColor = "text-green-500";
      iconColor = "fill-green-500";
      break;
    case "error":
      textStyle += [textStyle, "underline"].join(" ");
      textColor = "text-orange-600";
      iconColor = "fill-orange-600";
      break;
    case "orange":
      textColor = "text-orange-700";
      iconColor = "fill-orange-700";
      break;
    case "dark":
      textColor = "text-gray-900";
      iconColor = "fill-gray-900";
      break;
    case "gray":
      textColor = "text-gray-500";
      iconColor = "fill-gray-500";
      break;
    default: // defaults to dark
      textColor = "text-gray-900";
      iconColor = "fill-gray-900";
  }

  return (
    <button
      className={`TextButton ${clsx(
        "peer relative inline-flex items-center whitespace-nowrap underline-offset-4 transition-colors duration-300 focus-visible:underline focus-visible:outline-none disabled:pointer-events-none",
        {
          "items-center": position === "center",
          "!pointer-events-none !opacity-50": disabled,
          "pointer-events-none": loading,
        }
      )}`}
      onClick={onClick}
      disabled={disabled || loading}
      data-testid={dataTestId}
      type={submit ? "submit" : "button"}
    >
      {loading && (
        <svg
          className={`absolute inset-x-0 mx-auto animate-spin ${textColor} ${loaderSize}`}
          data-testid="loader-testid"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      )}

      {/* For left icon */}
      {icon && !iconRight && label && (
        <Icon
          icon={icon}
          iconStyle={`${iconColor} peer mr-1 ${
            loading ? "fill-transparent" : ""
          }`}
          size={iconSize}
        />
      )}

      {/* For centered icon */}
      {icon && !iconRight && !label && (
        <Icon
          icon={icon}
          iconStyle={`${iconColor} ${loading ? "peer fill-transparent" : ""}`}
          size={iconSize}
        />
      )}

      {label && (
        <p
          className={`
          ${textColor} ${textStyle}
          ${
            loading ? "!text-transparent" : ""
          } underline-offset-4 hover:underline peer-hover:underline peer-focus-visible:underline`}
        >
          {label}
        </p>
      )}

      {/* For right icon */}
      {icon && iconRight && !iconLeft && label && (
        <Icon
          icon={icon}
          iconStyle={`${iconColor} ml-1 ${loading ? "fill-transparent" : ""}`}
          size={iconSize}
        />
      )}
      {icon && !label && <span className="sr-only">{icon}</span>}
    </button>
  );
}
