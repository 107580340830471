import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Icon from "../icon/Icon";

type PropsT = {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
  title: string;
  footer?: React.ReactNode;
};

const Drawer = (props: PropsT) => {
  const { open, setOpen, title, children, footer } = props;

  return (
    <Transition show={open} data-testid="drawer">
      <Dialog className="relative z-20" onClose={setOpen}>
        <Transition.Child
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md md:max-w-lg">
                  <div className="DrawerBody flex h-full flex-col overflow-y-auto bg-white shadow-xl">
                    <div className="DrawerHeader flex flex-shrink-0 border-b px-4 py-3 sm:px-6">
                      <div className="flex w-full items-center justify-between">
                        <Dialog.Title className="font-gilroy text-base font-bold text-gray-900 sm:text-xl">
                          {title}
                        </Dialog.Title>
                        <div className="flex items-center">
                          <button
                            data-testid="close-button"
                            type="button"
                            className="group relative rounded-full bg-white p-1 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-gray-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <Icon
                              icon="x"
                              iconStyle="fill-gray-500 group-hover:fill-gray-900"
                              size="18"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll">
                      <div className="flex-1 px-4 sm:px-6">{children}</div>
                    </div>
                    {footer && footer}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Drawer;
