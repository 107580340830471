import Avatar from "./avatar";

type PropsT = {
  users?: UserType[];
  companies?: CompanyType[];
  loading?: boolean;
};

export default function MultiAvatars(props: PropsT) {
  const { users, companies, loading } = props;

  return (
    <div className="flex -space-x-2 overflow-hidden">
      {users
        ? users
            ?.map((user, idx) => (
              <Avatar
                small
                key={`${user?.id}-${idx}`}
                user={user}
                border
                loading={loading}
              />
            ))
            .slice(0, 4)
        : companies
            ?.map((company, idx) => (
              <Avatar
                small
                key={`${company?.id}-${idx}`}
                company={company}
                border
                loading={loading}
              />
            ))
            .slice(0, 4)}
    </div>
  );
}
