import clsx from "clsx";
import React from "react";

interface TextAreaInputProps {
  // all text area inputs have these text fields
  "data-testid"?: string;
  name?: string;
  label?: string;
  hint?: string;
  id?: string;
  value?: string | number;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  // some text inputs want these fields
  wide?: boolean;
  error?: string | number;
  rows?: number;
  // handlers
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLTextAreaElement>) => void;
  onBlur?: () => void;
}

export default function TextAreaInput(props: TextAreaInputProps) {
  const {
    name,
    label,
    hint,
    id,
    value,
    placeholder,
    disabled,
    error,
    rows = 3,
    wide,
    className,
    onKeyDown,
    onChange,
    onPaste,
    onBlur,
    "data-testid": dataTestId,
  } = props;

  const idForInput = id ? id : `${label || ""}-text-area-input`;

  return (
    <div
      className={clsx(
        "TextAreaInput w-full",
        {
          "w-full md:w-80": !wide,
        },
        className
      )}
      data-testid={idForInput}
    >
      {label && (
        <label
          htmlFor={idForInput}
          className="text-sm font-medium text-gray-900"
        >
          {label}
        </label>
      )}

      <textarea
        id={idForInput}
        className={clsx(
          "mt-1 w-full rounded-lg border border-gray-300 bg-white text-sm text-gray-900 placeholder:text-gray-500 focus:outline-none focus:ring-0",
          {
            "bg-white placeholder:text-gray-500 focus:border-gray-900 focus:outline-none focus:ring-0 active:border-gray-900":
              !(disabled || error),
            // "bg-gray-100 border-gray-100 focus:border-gray-100 active:border-gray-100 text-gray-500":
            //   disabled,
            "border-orange-600 bg-orange-100": error,
          }
        )}
        name={name}
        value={value || ""}
        placeholder={placeholder || "Write text here..."}
        onChange={onChange}
        onPaste={onPaste}
        rows={rows}
        onBlur={onBlur}
        data-testid={dataTestId}
        onKeyDown={onKeyDown}
      />

      {error && (
        <div className="mb-1 text-sm font-semibold text-orange-600">
          {error}
        </div>
      )}
      {hint && <div className="text-xs text-gray-500">{hint}</div>}
    </div>
  );
}
