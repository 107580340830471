import clsx from "clsx";
import { ForwardedRef, forwardRef, useState } from "react";
import { Button } from "../..";
import { CalendarDayT, setupMonth } from "./helper";

type PropsT = {
  open: boolean;
  value?: Date;
  onSelect: (newDate: Date) => void;
};

export default forwardRef(function Calendar(
  props: PropsT,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { open, value, onSelect } = props;

  const today = new Date();

  const [currentDays, setCurrentDays] = useState<CalendarDayT[]>(
    value
      ? setupMonth(value.getMonth(), value.getFullYear(), value, today)
      : setupMonth(today.getMonth(), today.getFullYear(), today, today)
  );

  const changeMonth = (month: number) => {
    const newMonth = month > 11 ? 0 : month < 0 ? 11 : month;
    const newYear =
      month > 11
        ? new Date(value || today).getFullYear() + 1
        : month < 0
          ? new Date(value || today).getFullYear() - 1
          : new Date(value || today).getFullYear();

    setCurrentDays(setupMonth(newMonth, newYear, value || today, new Date()));
  };

  const handleSelect = (date: CalendarDayT) => {
    onSelect(new Date(date.date));

    setCurrentDays(
      currentDays.map((day) => {
        return {
          ...day,
          isSelected: date === day,
        };
      })
    );
  };

  return (
    <div
      className={clsx(
        "Calendar z-30 mt-2 rounded-lg border border-gray-200 bg-white p-4 shadow-lg",
        {
          absolute: open,
          hidden: !open,
        }
      )}
      ref={ref}
    >
      <div className="flex items-center justify-between">
        <Button
          icon="caret-left"
          customIconSize="20"
          color="transparent"
          size="sm"
          onClick={() =>
            changeMonth((currentDays[10].date as Date).getMonth() - 1)
          }
        />
        <h2 className="text-base font-semibold text-gray-900">
          {(currentDays[10].date as Date)?.toLocaleString("default", {
            month: "long",
          })}{" "}
          {(currentDays[10].date as Date)?.toLocaleString("default", {
            year: "numeric",
          })}
        </h2>
        <Button
          icon="caret-right"
          customIconSize="20"
          size="sm"
          color="transparent"
          onClick={() =>
            changeMonth((currentDays[10].date as Date).getMonth() + 1)
          }
        />
      </div>

      <div className="mt-4 grid grid-cols-7 text-center text-sm font-semibold text-gray-900">
        <div>Su</div>
        <div>Mo</div>
        <div>Tu</div>
        <div>We</div>
        <div>Th</div>
        <div>Fr</div>
        <div>Sat</div>
      </div>

      <div className="mt-2 grid grid-cols-7 text-sm font-semibold">
        {currentDays.map((day, dayIdx) => (
          <div key={dayIdx} className={clsx({ "text-gray-900": dayIdx > 6 })}>
            <button
              type="button"
              disabled={day.placeholder || !day.isClickable}
              onClick={() => {
                if (day.isClickable) {
                  handleSelect(day);
                }
              }}
              className={clsx(
                day.isSelected && "text-gray-900",
                !day.isSelected && day.isToday && "text-orange-600",
                !day.isSelected && !day.isToday && "text-gray-900",
                day.isSelected && day.isToday && "bg-orange-100",
                day.isSelected &&
                  !day.isToday &&
                  "rounded-full bg-gray-900 text-white",
                !day.isSelected && "hover:bg-gray-100 hover:text-gray-900",
                (day.isSelected || day.isToday) && "",
                "flex h-8 w-8 items-center justify-center rounded-full"
              )}
            >
              <time dateTime={day.date.toString()}>
                {day
                  ? day.date && !day.placeholder
                    ? typeof day?.date === "object"
                      ? day?.date.toLocaleDateString("en-CA", {
                          day: "numeric",
                        })
                      : day?.date
                    : ""
                  : ""}
              </time>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
});
