import { useEffect, useState } from "react";
import { fetchInvoice } from "../../../../apis/invoices";
import { useAuth } from "../../../../state/AuthContexts";
import { Button, Modal, useToast } from "../../../shared/components";
import { useInterval } from "../../../shared/hooks";

type PropsT = {
  id: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  invoice: InvoiceType;
  isBill: boolean;
  refresh?: () => void;
};

export default function SyncSuccessModal(props: PropsT) {
  const { open, setOpen, isBill, id, invoice, refresh } = props;
  const { user, company } = useAuth();
  const { toast } = useToast();
  const [syncSuccessful, setSyncSuccessful] = useState<boolean>(false);

  useInterval(
    async () => {
      if (company && user) {
        const { invoice } = await fetchInvoice({
          isPublic: Boolean(!user?.token),
          uniqueInvoiceId: id,
        });
        const success =
          (isBill && !!invoice.buyer_erp_id) ||
          (!isBill && !!invoice.seller_erp_id);

        setSyncSuccessful(success);
      }
    }, // callback function to run
    5000, // every 5 seconds
    5, // run only 5 times
    async () => {
      setOpen(false);
      toast({
        variant: "error",
        title: `An error occured while syncing your data. Please contact support for assistance.`,
      });
    } // onFinish callback
  );

  useEffect(() => {
    if (syncSuccessful) {
      let title;
      if (isBill) {
        title = `Bill ${id} synced successfully!`;
      } else {
        title = `Invoice ${id} synced successfully!`;
      }

      refresh?.();

      setOpen(false);
      toast({
        variant: "success",
        title,
      });
    }
  }, [syncSuccessful, isBill, setOpen, refresh, id, toast]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={`Syncing started in background for ${invoice?.invoice_identifier}`}
    >
      <div className="p-3 md:w-[692px] md:p-5" data-testid="sync-success-modal">
        <div className="flex flex-col">
          <p className="whitespace-pre-wrap text-sm text-gray-900">
            This may take a few minutes. You can close this window and complete
            other tasks or you can leave this window open and wait until the
            process is complete.
          </p>

          <div className="flex justify-end pt-6">
            <Button onClick={() => setOpen(false)} label="Close" />
          </div>
        </div>
      </div>
    </Modal>
  );
}
