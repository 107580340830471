export type CalendarDayT = {
  date: Date | string;
  placeholder: boolean;
  isClickable: boolean;
  isSelected: boolean;
  weekdayIndex: number;
  isCurrentMonth: boolean;
  isToday: boolean;
};

// Takes in month and eyar in numbers; returns an array of each days in
const getDaysInMonth = (month: number, year: number): Date[] =>
  new Array(31)
    .fill("")
    .map((v, i) => new Date(year, month, i + 1))
    .filter((v) => v.getMonth() === month);

// take in month and year to generate a CalendarDau array
// selectedDate.getMonth(), selectedDate.getFullYear()
export const setupMonth = (
  month: number,
  year: number,
  selectedDay: Date,
  today: Date
) => {
  const selectedDate = new Date(selectedDay).toLocaleDateString("en-ca");
  const daysInMonth = getDaysInMonth(month, year);
  const formatedDaysInMonth = daysInMonth.map((day) => {
    return {
      isSelected: new Date(day).toLocaleDateString("en-CA") === selectedDate,
      isClickable: true,
      weekdayIndex: new Date(day).getDay(),
      date: day,
      isCurrentMonth: new Date(day).getMonth() === today.getMonth(),
      isToday:
        new Date(day).toLocaleDateString("en-CA") ===
        today.toLocaleDateString("en-CA"),
      placeholder: false,
    } as CalendarDayT;
  });

  if (formatedDaysInMonth && formatedDaysInMonth?.[0]?.weekdayIndex > 0) {
    const placeholders: CalendarDayT[] = [];
    for (let i = 0; i < formatedDaysInMonth[0].weekdayIndex; i++) {
      placeholders.push({
        date: i.toString(),
        placeholder: true,
        isClickable: false,
        isSelected: false,
        weekdayIndex: 0,
        isCurrentMonth: false,
        isToday: false,
      } as CalendarDayT);
    }

    formatedDaysInMonth.unshift(...placeholders);
  }

  return formatedDaysInMonth;
};
