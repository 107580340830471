import { useRouter } from "next/router";
import { useAuth } from "../../../../state";
import HorizontalStepper from "../steppers/horizontalStepper";

const tabs = [
  { status: ["/profile"], name: "My profile", current: false },
  { status: ["/profile/company"], name: "Company", current: false },
  { status: ["/profile/team"], name: "Team", current: false },
  { status: ["/profile/workflows"], name: "Workflows", current: false },
  { status: ["/profile/banking"], name: "Banking", current: false },
  { status: ["/profile/integrations"], name: "Integrations", current: false },
  { status: ["/profile/documents"], name: "Documents", current: false },
  { status: ["/profile/notifications"], name: "Notifications", current: false },
  { status: ["/profile/paycycles"], name: "Pay cycles", current: false },
];

export default function ProfileNav() {
  const router = useRouter();
  const { getCompany, user } = useAuth();

  const initialState = tabs.map((el) => {
    return { ...el, current: router.asPath === el.status[0] };
  });

  const handleClick = async (status: string) => {
    router.push(`${status}`);
    if (user?.token) {
      await getCompany(user?.token);
    }
  };

  return (
    <div className="ProfileNav" data-testid="profile-nav">
      <div className="flex flex-col md:flex-row md:justify-between">
        <div className="flex flex-col">
          <div className="font-gilroy text-3xl font-bold text-black">
            Account settings
          </div>
          <div className="text-base text-gray-900">
            Manage your company settings and profile details here.
          </div>
        </div>
      </div>
      <div className="border-b border-gray-200 pt-4">
        <HorizontalStepper tabs={initialState} onClick={handleClick} />
      </div>
    </div>
  );
}
