import { EntriesStatusT } from "../types";

export const getSellerEPState = (
  invoice: InvoiceType,
  entity: OrganizationalEntityT | null,
  connection?: ConnectionT
): EntriesStatusT => {
  if (connection?.connection_type === "xero") {
    return {
      state: "disabled",
      message: "Xero integration is not supported at the moment",
    };
  }
  if (invoice?.seller_erp_early_pay_entries_state === "in-progress") {
    return {
      state: "in-progress",
      message: "Processing",
    };
  }

  if (invoice?.seller_erp_early_pay_entries_state === "complete") {
    return {
      state: "complete",
      message: "Complete",
    };
  }

  if (!invoice?.ep_paid_at) {
    return {
      state: "disabled",
      message: "Invoice has not been early paid yet",
    };
  }

  if (!entity?.invoice_clearing_account_erp_id) {
    return {
      state: "disabled",
      message:
        "Invoice clearing account not set up. Please check the integration settings page",
    };
  }

  if (!entity?.invoice_early_payment_expense_account_erp_id) {
    return {
      state: "disabled",
      message:
        "Expense account not set up for early payments. Please check the integration settings page",
    };
  }

  if (!entity?.default_erp_bank_account_invoices) {
    return {
      state: "disabled",
      message:
        "Early payment bank account not set up. Please check the integration settings page",
    };
  }

  if (!entity?.quickly_customer_erp_id) {
    return {
      state: "disabled",
      message:
        "Quickly vendor not set up. Please check the integration settings page",
    };
  }

  return { state: "ready" };
};
