import clsx from "clsx";
import Icon from "../icon/Icon";

type PropsT = {
  tabs: {
    name: string;
    current: boolean;
    icon?: string;
    status: Array<string>;
    emptyStatus?: string;
  }[];
  onClick: (status: string) => void;
};

export default function HorizontalStepper(props: PropsT) {
  const { tabs, onClick } = props;

  return (
    <nav
      className="flex flex-wrap gap-x-4 whitespace-nowrap md:gap-x-8"
      aria-label="Tabs"
      data-testid="horizontal-stepper"
    >
      {tabs.map((tab) => (
        <button
          type="button"
          key={tab.name}
          onClick={() => onClick(tab.status[0])}
          className={clsx(
            tab.current ? "text-gray-900" : "text-gray-500 hover:text-gray-900",
            "group relative flex h-10 items-center text-sm font-medium outline-none"
          )}
          role="tab"
          aria-current={tab.current ? "page" : undefined}
        >
          {tab.icon && (
            <Icon
              iconStyle={
                tab.current
                  ? "text-gray-900 mr-2"
                  : "text-gray-500 group-hover:text-gray-900 mr-2"
              }
              icon={tab.icon}
            />
          )}
          {tab.name}
          {tab.current && (
            <div className="absolute bottom-0 -mb-[0.5px] h-[2px] w-full rounded-tl-full rounded-tr-full bg-gray-900" />
          )}
        </button>
      ))}
    </nav>
  );
}
