// todo: write tests for this
export const useButtonStyles = ({
  outline = false,
  color,
  size,
  label = "",
  hideLabelOnMobile = false,
}: {
  outline?: boolean;
  color?:
    | "primary"
    | "orange"
    | "green"
    | "alternative"
    | "transparent"
    | "dark"
    | "white"
    | "gradient"
    | "shadow";
  size?: "xs" | "sm" | "base" | "lg" | "xl";
  label?: string;
  hideLabelOnMobile?: boolean;
}): {
  buttonSize: string;
  textColor: string;
  textStyle: string;
  backgroundColor: string;
  borderColor: string;
  iconColor: string;
  iconSize: string;
  loaderSize: string;
} => {
  let buttonSize = "";
  let textColor = "";
  let textStyle = "";
  let backgroundColor = "";
  let borderColor = "";
  let iconColor = "";
  let iconSize = "";
  let loaderSize = "";

  switch (size) {
    case "xs":
      buttonSize = label ? "h-[34px] px-[14px]" : "h-[20px] w-[20px]";
      textStyle = "font-medium text-xs";
      iconSize = "12";
      loaderSize = "h-3 w-3";
      break;
    case "sm":
      buttonSize = label ? "h-[37px] px-4" : "h-[28px] w-[28px]";
      textStyle = "font-medium text-sm";
      iconSize = "12";
      loaderSize = "h-4 w-4";
      break;
    case "base":
      buttonSize = hideLabelOnMobile
        ? label
          ? "h-[40px] w-[40px] sm:h-[41px] sm:w-fit sm:px-6"
          : ""
        : label
          ? "h-[41px] px-6"
          : "h-[34px] w-[34px]";
      textStyle = "font-medium text-sm";
      iconSize = "14";
      loaderSize = "h-4 w-4";
      break;
    case "lg":
      buttonSize = label ? "h-12 px-6" : "h-[40px] w-[40px]";
      textStyle = "font-medium text-base";
      iconSize = "16";
      loaderSize = "h-5 w-5";
      break;
    case "xl":
      buttonSize = label ? "h-[52px] px-[28px]" : "h-11 w-11";
      textStyle = "font-medium text-base";
      iconSize = "18";
      loaderSize = "h-5 w-5";
      break;
    default: // default to base
      buttonSize = hideLabelOnMobile
        ? label
          ? "h-[40px] w-[40px] sm:h-[41px] sm:w-fit sm:px-6"
          : ""
        : label
          ? "h-[41px] px-6"
          : "h-[34px] w-[34px]";
      textStyle = "font-medium text-sm";
      iconSize = "14";
      loaderSize = "h-4 w-4";
  }

  switch (color) {
    case "primary":
      textColor = "text-white";
      backgroundColor =
        "bg-primary-500 hover:bg-primary-700 focus-visible:outline-primary-700";
      borderColor = "border border-transparent rounded-full";
      iconColor = "fill-white";
      break;
    case "orange":
      textColor = "text-white";
      backgroundColor =
        "bg-orange-600 hover:bg-orange-700 focus-visible:outline-orange-700";
      borderColor = "border border-transparent rounded-full";
      iconColor = "fill-white";
      break;
    case "green":
      textColor = "text-white";
      backgroundColor =
        "bg-green-600 hover:bg-green-700 focus-visible:outline-green-700";
      borderColor = "border border-transparent rounded-full";
      iconColor = "fill-white";
      break;
    case "alternative":
      textColor = "text-gray-900";
      backgroundColor = "bg-white hover:shadow focus-visible:outline-gray-900";
      borderColor = "border border-gray-300 hover:border-gray-900 rounded-lg";
      iconColor = "fill-gray-900";
      break;
    case "transparent":
      textColor = "text-gray-900";
      backgroundColor =
        "bg-transparent hover:bg-gray-100 hover:bg-gray-100 focus-visible:outline-gray-500";
      if (outline) {
        borderColor =
          "border border-gray-200 focus-visible:outline-gray-900 rounded-full";
      } else {
        borderColor = "border border-transparent rounded-full";
      }
      iconColor = "fill-gray-500 group-hover:fill-gray-900";
      break;
    case "gradient":
      textColor = "text-white";
      backgroundColor =
        "bg-gradient-2 hover:bg-gradient-1 focus-visible:outline-primary-600";
      borderColor = "border-transparent rounded-full";
      iconColor = "fill-white";
      break;
    case "white":
      if (outline) {
        textColor = "text-gray-900";
        backgroundColor =
          "bg-white hover:bg-gray-100 focus-visible:outline-gray-200";
        borderColor = "border border-gray-200 rounded-full";
        iconColor = "fill-gray-900";
      } else {
        textColor = "text-gray-900";
        backgroundColor =
          "bg-white hover:bg-gray-100 focus-visible:outline-gray-900";
        borderColor = "border border-transparent rounded-full";
        iconColor = "fill-gray-900";
      }
      break;
    case "dark":
      if (outline) {
        textColor = "text-gray-900";
        backgroundColor =
          "bg-white hover:bg-gray-100 focus-visible:outline-gray-900";
        borderColor = "border border-gray-900 rounded-full";
        iconColor = "fill-gray-900";
      } else {
        textColor = "text-white";
        backgroundColor =
          "bg-gray-800 hover:bg-gray-700 focus-visible:outline-gray-900";
        borderColor = "border border-transparent rounded-full";
        iconColor = "fill-white";
      }
      break;
    case "shadow":
      textColor = "text-gray-900";
      backgroundColor =
        "bg-white hover:bg-gray-100 focus-visible:outline-gray-900";
      borderColor = "border border-transparent rounded-lg shadow-sm";
      iconColor = "fill-gray-900";
      break;
    default: // defaults to dark
      if (outline) {
        textColor = "text-gray-900";
        backgroundColor =
          "bg-white hover:bg-gray-100 focus-visible:outline-gray-900";
        borderColor = "border border-gray-900 rounded-full";
        iconColor = "fill-gray-900";
      } else {
        textColor = "text-white";
        backgroundColor =
          "bg-gray-800 hover:bg-gray-700 focus-visible:outline-gray-900";
        borderColor = "border border-transparent rounded-full";
        iconColor = "fill-white";
      }
  }

  return {
    buttonSize,
    textColor,
    textStyle,
    backgroundColor,
    borderColor,
    iconColor,
    iconSize,
    loaderSize,
  };
};
