import clsx from "clsx";
import { ReactNode } from "react";
import Icon from "../icon/Icon";

type PropsT = {
  id?: string;
  title?: string;
  subtitle?: string;
  icon?: string;
  value?: boolean;
  size?: "lg" | "md" | "sm";
  border?: "lg" | "xl";
  wide?: boolean;
  position?: "right" | "left";
  onClick?: () => void;
  "data-testid"?: string;
  inline?: boolean;
  variant?: "default" | "snug";
  badge?: ReactNode;
  selectionType?: "radio" | "checkbox";
  showCheck?: boolean;
};

export default function SelectionBox(props: PropsT) {
  const {
    id,
    title,
    subtitle,
    position = "right",
    size = "lg",
    icon,
    value,
    wide,
    onClick,
    variant = "default",
    "data-testid": dataTestId,
    inline,
    badge,
    border = "xl",
    selectionType = "checkbox",
    showCheck = true,
  } = props;

  const inputId = id || title;
  const testId = dataTestId || title?.toLowerCase()?.replace(" ", "-");
  const shouldShowCheckbox =
    (selectionType === "checkbox" || (selectionType === "radio" && value)) &&
    showCheck;
  let check;

  if (selectionType === "checkbox") {
    check = (
      <Icon
        icon={value ? "check-circle-fill" : "circle"}
        iconStyle={clsx("ml-auto", {
          "fill-gray-900": value && selectionType === "checkbox",
          "fill-gray-200": !value && selectionType === "checkbox",
        })}
        size="20"
      />
    );
  } else {
    check = <Icon icon="check" iconStyle="ml-auto fill-gray-900" size="16" />;
  }

  return (
    <div
      className={clsx(
        "peer relative focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-gray-900",
        {
          "w-full": wide,
          "rounded-xl": border === "xl",
          "rounded-lg": border === "lg",
        }
      )}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.code === "Space") onClick?.();
      }}
    >
      <input
        id={inputId}
        type="checkbox"
        checked={value}
        className="hidden"
        readOnly
      />

      <label
        onClick={onClick}
        htmlFor={inputId}
        data-testid={testId}
        className={clsx(
          "relative inline-flex min-w-[11rem] cursor-pointer border bg-white hover:border-gray-900",
          {
            "p-4": variant === "default",
            "px-[24px] py-[14px]": variant === "snug",
            "border-gray-900": value,
            "border-gray-300": !value,
            "w-full": wide,
            "flex-col": !inline,
            "flex-row justify-between": inline,
            "rounded-xl": border === "xl",
            "rounded-lg": border === "lg",
          }
        )}
      >
        <div className="flex w-full flex-row items-center justify-start">
          {position === "left" && shouldShowCheckbox && check}

          {wide && icon && (
            <Icon icon={icon} iconStyle="fill-gray-900" size="32" />
          )}

          <div
            className={clsx("text-left", {
              "w-full pl-2": wide,
            })}
          >
            {!wide && icon && (
              <Icon icon={icon} iconStyle="fill-gray-900" size="32" />
            )}

            {title && (
              <div
                className={clsx("w-full text-gray-900", {
                  "font-gilroy text-lg font-bold": size === "lg",
                  "text-md font-semibold": size === "md",
                  "text-sm font-semibold": size === "sm",
                })}
              >
                {title}
              </div>
            )}
          </div>
          {position === "right" && shouldShowCheckbox && check}
          <div className="ml-auto justify-self-end">{badge}</div>
        </div>

        {subtitle && (
          <div
            className={clsx("w-full whitespace-nowrap text-sm text-gray-500", {
              "mt-2": !!title,
            })}
          >
            {subtitle}
          </div>
        )}
      </label>
    </div>
  );
}
