import clsx from "clsx";
import {
  ReactNode,
  UIEvent,
  UIEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";

type PropsT = {
  children: ReactNode;
  scrollX?: boolean;
  gray?: boolean;
  classname?: string;
};

const ScrollArea = (props: PropsT) => {
  const { children, scrollX, classname, gray } = props;
  const [topOpacity, setTopOpacity] = useState(0);
  const [bottomOpacity, setBottomOpacity] = useState(100);

  const scrollAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollAreaRef.current) {
      const scrollArea = scrollAreaRef.current;

      if (scrollX) {
        const scrollWidth = scrollArea.scrollWidth;
        const clientWidth = scrollArea.clientWidth;
        if (scrollWidth === clientWidth) {
          setTopOpacity(0);
          setBottomOpacity(0);
        }
      } else {
        const scrollHeight = scrollArea.scrollHeight;
        const clientHeight = scrollArea.clientHeight;
        if (scrollHeight === clientHeight) {
          setTopOpacity(0);
          setBottomOpacity(0);
        }
      }
    }
  }, [scrollX]);

  const handleScroll = (event: UIEvent<HTMLDivElement, UIEvent>) => {
    event.stopPropagation();
    const target = event.currentTarget;

    if (scrollX) {
      const scrollTop = target.scrollLeft;
      const scrollHeight = target.scrollWidth - target.clientWidth;

      const topOpacity = Math.min(scrollTop / 100, 1);
      const bottomOpacity = Math.min((scrollHeight - scrollTop) / 100, 1);

      setTopOpacity(topOpacity);
      setBottomOpacity(bottomOpacity);
    } else {
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight - target.clientHeight;
      const topOpacity = Math.min(scrollTop / 100, 1);
      const bottomOpacity = Math.min((scrollHeight - scrollTop) / 100, 1);

      setTopOpacity(topOpacity);
      setBottomOpacity(bottomOpacity);
    }
  };

  return (
    <div className="ScrollArea relative">
      {scrollX ? (
        <>
          <div
            className={clsx(
              "leftGradient duration-250 pointer-events-none absolute inset-y-0 left-0 w-8 bg-gradient-to-r transition-opacity",
              {
                "from-gray-50": gray,
                "from-white": !gray,
              }
            )}
            style={{ opacity: topOpacity }}
          />
          <div
            ref={scrollAreaRef}
            className={`ScrollAreaChild ${classname}`}
            onScroll={handleScroll as unknown as UIEventHandler<HTMLDivElement>}
          >
            {children}
          </div>
          <div
            className={clsx(
              "rightGradient duration-250 pointer-events-none absolute inset-y-0 right-0 w-8 bg-gradient-to-l transition-opacity",
              {
                "from-gray-50": gray,
                "from-white": !gray,
              }
            )}
            style={{ opacity: bottomOpacity }}
          />
        </>
      ) : (
        <>
          <div
            className={clsx(
              "topGradient duration-250 pointer-events-none absolute inset-x-0 -top-0.5 h-8 bg-gradient-to-b transition-opacity",
              {
                "from-gray-50": gray,
                "from-white": !gray,
              }
            )}
            style={{ opacity: topOpacity }}
          />
          <div
            ref={scrollAreaRef}
            className={`ScrollAreaChild ${classname}`}
            onScroll={handleScroll as unknown as UIEventHandler<HTMLDivElement>}
          >
            {children}
          </div>
          <div
            className={clsx(
              "bottomGradient duration-250 pointer-events-none absolute inset-x-0 -bottom-0.5 h-8 bg-gradient-to-t transition-opacity",
              {
                "from-gray-50": gray,
                "from-white": !gray,
              }
            )}
            style={{ opacity: bottomOpacity }}
          />
        </>
      )}
    </div>
  );
};

export default ScrollArea;
