import DayOfMonthSelector from "../datePicker/dayOfMonthSelector";
import RadioInput from "../radioInput";
import SelectInput from "../selectInput";
import TextInput from "../textInput";

import { PaymentCycleT } from "../../../../../apis/company";
import { capitalizeName } from "../../../helpers";
import InlineAlert from "../../alerts/inlineAlert";
import Button from "../../buttons/button";
import Icon from "../../icon/Icon";
import Tooltip from "../../tooltip";

type PropsT = {
  onChange: (paymentCycles: PaymentCycleT[]) => void;
  payCycles: PaymentCycleT[];
  errors: Record<string, ErrorT>;
};

export default function PaymentCycleInput(props: PropsT) {
  const { onChange, payCycles, errors } = props;

  const defaultPaymentCycle = {
    type: null,
    paymentCycleDay: "",
    paymentCycleDayOfWeek: "",
    paymentCycleEachDay: "",
    cutOffQuantity: "",
    cutOffFrequency: "days",
    showDaySelector: false,
  };

  const paymentCycleDays = [
    { label: "First", value: "first" },
    { label: "Second", value: "second" },
    { label: "Third", value: "third" },
    { label: "Fourth", value: "fourth" },
    { label: "Last", value: "last" },
  ];

  const paymentCycleDaysOfTheWeek = [
    { label: "Sunday", value: "sunday" },
    { label: "Monday", value: "monday" },
    { label: "Tuesday", value: "tuesday" },
    { label: "Wednesday", value: "wednesday" },
    { label: "Thursday", value: "thursday" },
    { label: "Friday", value: "friday" },
    { label: "Day", value: "day" },
    { label: "Business day", value: "businessDay" },
  ];

  const pr = new Intl.PluralRules("en-US", { type: "ordinal" });

  const suffixes = new Map([
    ["one", "st"],
    ["two", "nd"],
    ["few", "rd"],
    ["other", "th"],
  ]);

  const formatOrdinals = (n: number) => {
    const rule = pr.select(n);
    const suffix = suffixes.get(rule);
    return `${suffix}`;
  };

  return (
    <div
      className="PaymentCycle-input flex max-w-2xl flex-col space-y-8"
      data-testid="payment-cycle-input"
    >
      <div className="cycle-1 flex flex-col space-y-4">
        <div className="flex flex-col gap-4 sm:flex-row">
          <div className="flex min-w-20 whitespace-nowrap">
            <RadioInput
              label="On the"
              value={payCycles[0].type === "on-the"}
              multiSelect={false}
              onClick={() => {
                onChange([
                  {
                    ...payCycles[0],
                    type: "on-the",
                    paymentCycleEachDay: "",
                  },
                  ...payCycles.splice(1, 1),
                ]);
              }}
            />
          </div>

          <SelectInput
            error={errors.paymentCycle1?.paymentCycleDay}
            disabled={payCycles[0].type !== "on-the"}
            value={paymentCycleDays.find(
              (o) => o.value === payCycles[0].paymentCycleDay
            )}
            options={paymentCycleDays}
            onChange={(option) => {
              onChange([
                { ...payCycles[0], paymentCycleDay: option.value },
                ...payCycles.splice(1, 1),
              ]);
            }}
            placeholder="First"
          />

          <SelectInput
            error={errors.paymentCycle1?.paymentCycleDayOfWeek}
            disabled={payCycles[0].type !== "on-the"}
            value={paymentCycleDaysOfTheWeek.find(
              (o) => o.value === payCycles[0].paymentCycleDayOfWeek
            )}
            options={paymentCycleDaysOfTheWeek}
            onChange={(option) => {
              onChange([
                { ...payCycles[0], paymentCycleDayOfWeek: option.value },
                ...payCycles.splice(1, 1),
              ]);
            }}
            placeholder="Friday"
          />
        </div>
        <div className="flex flex-col gap-4 sm:flex-row">
          <div className="flex min-w-20 whitespace-nowrap">
            <RadioInput
              label="Each"
              value={payCycles[0].type === "each"}
              multiSelect={false}
              onClick={() => {
                onChange([
                  {
                    ...payCycles[0],
                    type: "each",
                    paymentCycleDay: "",
                    paymentCycleDayOfWeek: "",
                  },
                  ...payCycles.splice(1, 1),
                ]);
              }}
            />
          </div>
          <TextInput
            error={errors.paymentCycle1?.paymentCycleEachDay}
            value={payCycles[0].paymentCycleEachDay}
            label="Select a day"
            onChange={(e) => {
              // For clear
              onChange([
                {
                  ...payCycles[0],
                  paymentCycleEachDay: e.target.value.toString(),
                  showDaySelector: false,
                },
                ...payCycles.splice(1, 1),
              ]);
            }}
            onClick={() => {
              onChange([
                {
                  ...payCycles[0],
                  showDaySelector: !payCycles[0].showDaySelector,
                },
                ...payCycles.splice(1, 1),
              ]);
            }}
            disabled={payCycles[0].type !== "each"}
            clearable
          />

          <DayOfMonthSelector
            value={parseInt(payCycles[0].paymentCycleEachDay)}
            open={payCycles[0].showDaySelector}
            onSelect={(value) => {
              onChange([
                {
                  ...payCycles[0],
                  paymentCycleEachDay: value.toString(),
                  showDaySelector: false,
                },
                ...payCycles.splice(1, 1),
              ]);
            }}
          />
        </div>

        <div className="flex flex-col gap-4 sm:flex-row">
          <div className="flex min-w-20 flex-row items-center gap-2">
            <span className="whitespace-nowrap text-sm font-medium text-gray-900">
              Cut off
            </span>

            <Tooltip
              subtitle="Set the cut off time by which all payment requests must be submitted to be processed in the next payment cycle. After this time, any new requests will be processed in the following cycle."
              position="right"
            >
              <Icon icon="question" size="18" />
            </Tooltip>
          </div>

          <TextInput
            label="Cut off"
            number
            min={0}
            max={31}
            error={errors?.paymentCycle1?.cutOffQuantity}
            value={payCycles[0].cutOffQuantity}
            onChange={(e) => {
              onChange([
                {
                  ...payCycles[0],
                  cutOffQuantity: e.target.value,
                },
                ...payCycles.splice(1, 1),
              ]);
            }}
          />

          <SelectInput
            error={errors?.paymentCycle1?.cutOffFrequency}
            value={[
              { label: "Days before", value: "days" },
              { label: "Weeks before", value: "weeks" },
            ].find((o) => o.value === payCycles[0].cutOffFrequency)}
            options={[
              { label: "Days before", value: "days" },
              { label: "Weeks before", value: "weeks" },
            ]}
            onChange={(o) => {
              onChange([
                {
                  ...payCycles[0],
                  cutOffFrequency: o.value,
                },
                ...payCycles.splice(1, 1),
              ]);
            }}
            placeholder="Days before"
          />
        </div>
      </div>

      {payCycles && payCycles.length > 1 && (
        <>
          <div className="border-b" />
          <div className="cycle-2 flex flex-col space-y-4">
            <div className="flex flex-col gap-4 sm:flex-row">
              <div className="flex min-w-20 whitespace-nowrap">
                <RadioInput
                  label="On the"
                  value={payCycles[1].type === "on-the"}
                  multiSelect={false}
                  onClick={() => {
                    onChange([
                      payCycles[0],
                      {
                        ...payCycles[1],
                        type: "on-the",
                        paymentCycleEachDay: "",
                      },
                    ]);
                  }}
                />
              </div>

              <SelectInput
                error={errors.paymentCycle2?.paymentCycleDay}
                disabled={payCycles[1].type !== "on-the"}
                value={paymentCycleDays.find(
                  (o) => o.value === payCycles[1].paymentCycleDay
                )}
                options={paymentCycleDays}
                onChange={(option) => {
                  onChange([
                    payCycles[0],
                    { ...payCycles[1], paymentCycleDay: option.value },
                  ]);
                }}
                placeholder="First"
              />

              <SelectInput
                error={errors.paymentCycle2?.paymentCycleDayOfWeek}
                disabled={payCycles[1].type !== "on-the"}
                value={paymentCycleDaysOfTheWeek.find(
                  (o) => o.value === payCycles[1].paymentCycleDayOfWeek
                )}
                options={paymentCycleDaysOfTheWeek}
                onChange={(option) => {
                  onChange([
                    payCycles[0],
                    {
                      ...payCycles[1],
                      paymentCycleDayOfWeek: option.value,
                    },
                  ]);
                }}
                placeholder="Friday"
              />
            </div>
            <div className="flex flex-col gap-4 sm:flex-row">
              <div className="flex min-w-20 whitespace-nowrap">
                <RadioInput
                  label="Each"
                  value={payCycles[1].type === "each"}
                  multiSelect={false}
                  onClick={() => {
                    onChange([
                      payCycles[0],
                      {
                        ...payCycles[1],
                        type: "each",
                        paymentCycleDay: "",
                        paymentCycleDayOfWeek: "",
                      },
                    ]);
                  }}
                />
              </div>

              <TextInput
                error={errors.paymentCycle2?.paymentCycleEachDay}
                value={payCycles[1].paymentCycleEachDay}
                label="Select a day"
                onChange={(e) => {
                  // For clear

                  onChange([
                    payCycles[0],
                    {
                      ...payCycles[1],
                      paymentCycleEachDay: e.target.value.toString(),
                      showDaySelector: false,
                    },
                  ]);
                }}
                onClick={() => {
                  onChange([
                    payCycles[0],
                    {
                      ...payCycles[1],
                      showDaySelector: !payCycles[0].showDaySelector,
                    },
                  ]);
                }}
                disabled={payCycles[1].type !== "each"}
                clearable
              />
              <DayOfMonthSelector
                value={parseInt(payCycles[1].paymentCycleEachDay)}
                open={payCycles[1].showDaySelector}
                onSelect={(value) => {
                  onChange([
                    payCycles[0],
                    {
                      ...payCycles[1],
                      paymentCycleEachDay: value.toString(),
                      showDaySelector: false,
                    },
                  ]);
                }}
              />
            </div>
            <div className="flex flex-col gap-4 sm:flex-row">
              <div className="flex min-w-20 flex-row items-center gap-2">
                <span className="whitespace-nowrap text-sm font-medium text-gray-900">
                  Cut off
                </span>

                <Tooltip
                  subtitle="Set the cut off time by which all payment requests must be submitted to be processed in the next payment cycle. After this time, any new requests will be processed in the following cycle."
                  position="right"
                >
                  <Icon icon="question" size="18" />
                </Tooltip>
              </div>

              <TextInput
                error={errors.paymentCycle2?.cutOffQuantity}
                label="Cut off"
                number
                min={0}
                max={31}
                value={payCycles[1].cutOffQuantity}
                onChange={(e) => {
                  onChange([
                    payCycles[0],
                    {
                      ...payCycles[1],
                      cutOffQuantity: e.target.value,
                    },
                  ]);
                }}
              />

              <SelectInput
                error={errors.paymentCycle2?.cutOffFrequency}
                value={
                  [
                    { label: "Days before", value: "days" },
                    { label: "Weeks before", value: "weeks" },
                  ].find((o) => o.value === payCycles[1].cutOffFrequency) || {
                    label: "Days before",
                    value: "days",
                  }
                }
                options={[
                  { label: "Days before", value: "days" },
                  { label: "Weeks before", value: "weeks" },
                ]}
                onChange={(o) => {
                  onChange([
                    payCycles[0],
                    {
                      ...payCycles[1],
                      cutOffFrequency: o.value,
                    },
                  ]);
                }}
                placeholder="Days before"
              />
            </div>
          </div>
        </>
      )}

      <div className="flex justify-center">
        <Button
          color="alternative"
          iconLeft={true}
          size="lg"
          wide
          icon={
            payCycles && payCycles.length > 1
              ? "minus-circle-fill"
              : "plus-circle"
          }
          label={`${payCycles && payCycles.length > 1 ? "Remove" : "Add"} 2nd Payment schedule`}
          onClick={() => {
            if (payCycles.length > 1) {
              onChange([payCycles[0]]);
            } else {
              onChange([payCycles[0], defaultPaymentCycle]);
            }
          }}
        />
      </div>

      <div className="border-t" />

      {errors && (errors.paymentCycle1 || errors.paymentCycle2) && (
        <>
          {errors && errors.paymentCycle1 && errors.paymentCycle1?.type && (
            <InlineAlert
              type="error"
              title="Payment Cycle 1 Error"
              subtitle="Payment Cycle Type is required"
            />
          )}
          {errors && errors.paymentCycle2 && errors.paymentCycle2?.type && (
            <InlineAlert
              type="error"
              title="Payment Cycle 2 Error"
              subtitle="Payment Cycle 2 Type is required"
            />
          )}
        </>
      )}

      <p>
        <b>Summary:</b> You have{" "}
        {payCycles && payCycles.length > 1 ? "two" : "one"} payment cycle
        {payCycles && payCycles.length > 1 && "s"} that occur
        {payCycles && payCycles.length === 1 && "s"}{" "}
        {payCycles[0].type === "on-the" ? "on the" : "each"}{" "}
        {payCycles[0].type === "on-the"
          ? `${payCycles[0].paymentCycleDay} ${capitalizeName(payCycles[0].paymentCycleDayOfWeek)} of every month `
          : `${payCycles[0].paymentCycleEachDay || "n"}${formatOrdinals(parseInt(payCycles[0].paymentCycleEachDay))} day of the month `}
        with a cut off of {payCycles[0].cutOffQuantity || "n"}{" "}
        {payCycles[0].cutOffFrequency || "days"}
        {payCycles.length > 1 ? (
          <>
            {" and "}
            {payCycles[1].type === "on-the" ? "on the" : "each"}{" "}
            {payCycles[1].type === "on-the"
              ? `${payCycles[1].paymentCycleDay} ${capitalizeName(payCycles[1].paymentCycleDayOfWeek)} of every month `
              : `${payCycles[1].paymentCycleEachDay || "n"}${formatOrdinals(parseInt(payCycles[1].paymentCycleEachDay))} day of the month `}
            with a cut off of {payCycles[1].cutOffQuantity || "n"} day.
          </>
        ) : (
          ""
        )}
      </p>
    </div>
  );
}
