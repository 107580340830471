import { useState } from "react";
import { createManualERPConnection } from "../../../../apis/erp";
import { useAuth } from "../../../../state";
import {
  Button,
  Icon,
  InlineAlert,
  TextInput,
} from "../../../shared/components";

type PropsT = {
  onSuccess: () => void;
  setSetupType: (type: null | "railz" | "sageIntacct" | "sage300") => void;
};

const SetupSage300 = ({ onSuccess, setSetupType }: PropsT) => {
  const { getCompany, user } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [isError, setIsError] = useState(false);

  const handleCreateConnection = async () => {
    setLoading(true);
    setIsError(false);

    createManualERPConnection({
      type: "sage300",
      hh2_username: username,
      hh2_password: password,
    })
      .then(async (response) => {
        if (!response?.success) {
          throw new Error("Failed to create connection:", response?.message);
        }

        if (user) await getCompany(user.token);
        onSuccess();
      })
      .catch((e) => {
        console.error(e);
        setIsError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center justify-center">
        <Icon size="120" icon="sage300" iconStyle="-my-8" />
      </div>
      <TextInput
        label="HH2 Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        disabled={loading}
        wide
      />
      <TextInput
        label="HH2 Password"
        type="password"
        password
        value={password}
        disabled={loading}
        wide
        onChange={(e) => setPassword(e.target.value)}
      />

      {isError && (
        <InlineAlert
          type="error"
          title=""
          subtitle="Error connecting, please contact support."
        />
      )}

      <div className="flex justify-end gap-2">
        <Button
          onClick={() => {
            setSetupType(null);
            setUsername("");
            setPassword("");
            setLoading(false);
          }}
          label="Back"
          disabled={loading}
          color="transparent"
          outline
        />
        <Button
          onClick={handleCreateConnection}
          disabled={loading}
          loading={loading}
          label="Submit"
        />
      </div>
    </div>
  );
};

export default SetupSage300;
