import clsx from "clsx";
import Image from "next/image";
import { MouseEventHandler } from "react";
import Icon from "../icon/Icon";

type PropsT = {
  user?: Partial<UserType | ContactType>;
  company?: Partial<CompanyType> | (ContactType & CompanyType);
  small?: boolean;
  large?: boolean;
  onClick?: MouseEventHandler;
  border?: boolean;
  badgeIcon?: string; //success, pending, failure
  loading?: boolean;
  className?: string;
};

export default function Avatar(props: PropsT) {
  const {
    user,
    company,
    small,
    large,
    badgeIcon,
    loading,
    className = "",
  } = props;

  const getCharacter = () => {
    if (user?.first_name && user?.last_name) {
      return `${user.first_name.charAt(0)?.toLocaleUpperCase()}${user.last_name
        .charAt(0)
        ?.toLocaleUpperCase()}`;
    } else if (company?.name) {
      return `${company.name.charAt(0)?.toLocaleUpperCase()}`;
    } else {
      return <Icon icon="bolt" size="16" iconStyle="fill-white" />;
    }
  };

  return (
    <div
      className={clsx(
        "Avatar relative flex flex-shrink-0 items-center justify-center rounded-full hover:cursor-default",
        {
          [className]: className,
          "h-8 w-8 text-xs font-semibold text-gray-900": small,
          "h-10 w-10 text-xs font-semibold text-gray-900": !small && !large,
          "h-12 w-12 font-semibold text-gray-900": large,
          "border border-gray-200 bg-gray-100":
            user?.first_name || company?.name || loading,
          "bg-gradient-1": !(user?.first_name || company?.name) && !loading,
        }
      )}
      data-testid="avatar"
    >
      {loading ? (
        <div className="h-full w-full animate-pulse rounded-full border bg-gray-200" />
      ) : (
        <>
          {company?.logo_url ? (
            <Image
              className="rounded-full border border-white"
              src={company?.logo_url}
              alt="Avatar"
              fill
              sizes={small ? "32px, 32px" : large ? "48px, 48px" : "40px, 40px"}
            />
          ) : (
            getCharacter()
          )}
          {badgeIcon && (
            <div className="absolute -bottom-1 -right-1 flex h-5 w-5 items-center justify-center overflow-hidden rounded-full border-2 border-white">
              <Icon
                icon={
                  badgeIcon === "success"
                    ? "check-circle-fill"
                    : badgeIcon === "failure"
                      ? "minus-circle-fill"
                      : "dots-three-circle-fill"
                }
                iconStyle={
                  badgeIcon === "success"
                    ? "fill-green-400"
                    : badgeIcon === "failure"
                      ? "fill-orange-700"
                      : "fill-yellow-300"
                }
                size="20"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
