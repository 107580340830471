import { EntriesStatusT } from "../types";

export const getBuyerEPState = (
  invoice: InvoiceType,
  entity?: OrganizationalEntityT | null
): EntriesStatusT => {
  if (
    (invoice.bill_clearing_payment_pushcommunicationid &&
      !invoice.bill_clearing_payment_erp_id) ||
    (invoice.bill_early_payment_pushcommunicationid &&
      !invoice.bill_early_payment_erp_id)
  ) {
    return {
      state: "in-progress",
      message: "Processing",
    };
  }

  if (
    invoice.bill_clearing_payment_erp_id &&
    invoice.bill_early_payment_erp_id
  ) {
    return { state: "complete", message: "Complete" };
  }

  if (invoice?.status !== "paid") {
    return { state: "disabled", message: "Bill has not been early paid yet" };
  }

  if (
    !entity?.bill_clearing_account_pushcommunicationid &&
    !entity?.bill_clearing_account_erp_id
  ) {
    return {
      state: "disabled",
      message:
        "Bill clearing account not set up. Please check the integration settings page",
    };
  }

  if (!entity?.quickly_vendor_erp_id && !entity?.vendor_pushcommunicationid) {
    return {
      state: "disabled",
      message:
        "Quickly vendor not set up. Please check the integration settings page",
    };
  }

  return { state: "ready" };
};
