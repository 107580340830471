import clsx from "clsx";
import { Icon, IncreaseTouchTarget } from "..";

export interface IPaginationProps {
  rowsPerPage: number;
  setRowsPerPage?: (rowsPerPage: number) => void;
  page: number;
  setPage: (page: number) => void;
  totalItems?: number;
}

export default function Pagination(props: IPaginationProps) {
  const { rowsPerPage, page, setPage, totalItems = 0 } = props;

  const totalPages = Math.ceil(totalItems / rowsPerPage);

  const range = (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, i) => start + i);
  };

  const visiblePages = () => {
    const pageCount = 5;
    const halfPageCount = Math.floor(pageCount / 2);

    if (totalPages <= pageCount) {
      return range(1, totalPages);
    }
    if (page <= halfPageCount) {
      return [...range(1, pageCount - 2), "...", totalPages];
    }
    if (page > totalPages - halfPageCount) {
      return [1, "...", ...range(totalPages - pageCount + 3, totalPages)];
    }

    return [
      1,
      "...",
      ...range(page - halfPageCount + 1, page + halfPageCount - 1),
      "...",
      totalPages,
    ];
  };

  return (
    <footer className="Pagination mt-4 md:mt-6" data-testid="pagination">
      <div className="flex flex-col items-center rounded-lg border border-gray-200 bg-white p-4 md:h-16 md:flex-row md:justify-between">
        <div className="PaginationItems whitespace-nowrap pb-2 md:pb-0">
          <span className="text-sm text-gray-500">Showing</span>{" "}
          <span className="text-sm font-semibold text-gray-900">
            {page * rowsPerPage - rowsPerPage + 1}-
            {page * rowsPerPage >= totalItems ? totalItems : page * rowsPerPage}
          </span>{" "}
          <span className="text-sm text-gray-500">of</span>{" "}
          <span className="text-sm font-semibold text-gray-900">
            {totalItems}
          </span>
        </div>
        <nav className="PaginationButtons">
          <ul className="flex h-8 items-center -space-x-px text-sm">
            <li>
              <button
                data-testid="back-page-button"
                onClick={() => setPage(page - 1)}
                disabled={page === 1}
                className={`relative ml-0 flex h-8 items-center justify-center rounded-l-full border border-gray-300 bg-white pl-3 pr-2 transition-colors duration-300 hover:bg-gray-100 ${
                  page === 1 ? "hover:bg-white" : "group hover:bg-gray-100"
                }`}
              >
                <IncreaseTouchTarget>
                  <span className="sr-only">Back</span>
                  <Icon
                    icon="caret-left"
                    iconStyle="fill-gray-500 group-hover:fill-gray-900"
                    size="12"
                  />
                </IncreaseTouchTarget>
              </button>
            </li>

            {visiblePages().map((visiblePage, index) => (
              <li key={index}>
                <button
                  data-testid={`${visiblePage}-page-button`}
                  className={clsx(
                    "relative flex h-8 items-center justify-center border border-gray-300 px-3 transition-colors duration-300",
                    {
                      "bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-900":
                        page !== visiblePage,
                      "bg-gray-100 text-gray-900 hover:bg-gray-100 hover:text-gray-900":
                        page === visiblePage,
                      "hover:bg-white hover:text-gray-500":
                        visiblePage === "...",
                    }
                  )}
                  onClick={() => {
                    if (typeof visiblePage === "number") {
                      setPage(visiblePage);
                    }
                  }}
                  disabled={visiblePage === "..." || page === visiblePage}
                >
                  <IncreaseTouchTarget>{visiblePage}</IncreaseTouchTarget>
                </button>
              </li>
            ))}

            <li>
              <button
                data-testid="next-page-button"
                onClick={() => {
                  setPage(page + 1);
                }}
                disabled={page === totalPages || totalPages === 0}
                className={`relative ml-0 flex h-8 items-center justify-center rounded-r-full border border-gray-300 bg-white pl-2 pr-3 transition-colors duration-300 hover:bg-gray-100 ${
                  page === totalPages || totalPages === 0
                    ? "hover:bg-white"
                    : "group hover:bg-gray-100"
                }`}
              >
                <IncreaseTouchTarget>
                  <span className="sr-only">Next</span>
                  <Icon
                    icon="caret-right"
                    iconStyle="fill-gray-500 group-hover:fill-gray-900"
                    size="12"
                  />
                </IncreaseTouchTarget>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
}
