import clsx from "clsx";
import { MouseEventHandler } from "react";
import { Icon, IncreaseTouchTarget } from "..";
import { useButtonStyles } from "./helper";

type PropsT = {
  "data-testid"?: string;
  onClick?: MouseEventHandler;
  submit?: boolean;
  disabled?: boolean;
  loading?: boolean;
  wide?: boolean;
  label?: string;
  color?:
    | "primary"
    | "orange"
    | "green"
    | "alternative"
    | "transparent"
    | "dark"
    | "white"
    | "gradient"
    | "shadow";
  outline?: boolean;
  size?: "xs" | "sm" | "base" | "lg" | "xl";
  customIconSize?: string;
  customIconStyles?: string;
  icon?: string;
  iconLeft?: boolean;
  iconRight?: boolean;
  autoFocus?: boolean;
  hideLabelOnMobile?: boolean;
  align?: "left" | "center";
};

export default function Button(props: PropsT) {
  const {
    "data-testid": dataTestId,
    onClick,
    submit = false,
    loading = false,
    disabled = false,
    wide = false,
    label = "",
    outline = false,
    color,
    size,
    customIconSize,
    customIconStyles,
    icon = "",
    iconLeft = false,
    iconRight = false,
    autoFocus = false,
    hideLabelOnMobile,
    align = "center",
  } = props;

  const {
    buttonSize,
    textColor,
    textStyle,
    backgroundColor,
    borderColor,
    iconColor,
    iconSize,
    loaderSize,
  } = useButtonStyles({ outline, color, size, label, hideLabelOnMobile });

  return (
    <button
      className={`Button ${backgroundColor} ${buttonSize} ${borderColor} ${clsx(
        "group relative inline-flex min-w-fit items-center whitespace-nowrap transition-colors duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:pointer-events-none sm:flex-grow-0",
        {
          "!pointer-events-none !opacity-50": disabled,
          "pointer-events-none": loading,
          "w-full": wide,
          "justify-center": align === "center",
        }
      )}`}
      onClick={onClick}
      disabled={disabled || loading}
      data-testid={dataTestId}
      type={submit ? "submit" : "button"}
      autoFocus={autoFocus}
    >
      <IncreaseTouchTarget>
        {loading && (
          <svg
            className={`absolute inset-x-0 mx-auto animate-spin ${textColor} ${loaderSize}`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            data-testid="loader-testid"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        )}

        {/* For left icon */}
        {icon && !iconRight && label && (
          <Icon
            icon={icon}
            iconStyle={`${customIconStyles || iconColor} ${hideLabelOnMobile ? "ml-0 sm:mr-2" : "mr-2"} ${loading ? "fill-transparent" : ""}`}
            size={customIconSize || iconSize}
          />
        )}

        {/* For centered icon */}
        {icon && !label && (
          <Icon
            icon={icon}
            iconStyle={`${customIconStyles || iconColor} ${loading ? "fill-transparent" : ""}`}
            size={customIconSize || iconSize}
          />
        )}

        {label && (
          <span
            className={`${loading ? "!text-transparent" : ""} ${hideLabelOnMobile ? "hidden sm:block" : ""} ${textColor} ${textStyle}`}
          >
            {label}
          </span>
        )}

        {/* For right icon */}
        {icon && !iconLeft && iconRight && label && (
          <Icon
            icon={icon}
            iconStyle={`${customIconStyles || iconColor} ${hideLabelOnMobile ? "ml-0 sm:ml-2" : "ml-2"} ${loading ? "fill-transparent" : ""}`}
            size={customIconSize || iconSize}
          />
        )}

        {hideLabelOnMobile && label && <span className="sr-only">{label}</span>}
        {icon && !label && <span className="sr-only">{icon}</span>}
      </IncreaseTouchTarget>
    </button>
  );
}
