import { fetchWithAuth } from "../interceptor";
import {
  APIRequestCreateCompanyERPCustomerT,
  APIRequestCreateCompanyERPGLAccountT,
  APIRequestCreateCompanyERPVendorT,
  APIResponseGetERPConnectionStatusT,
  APIResponseGetERPCustomersT,
  APIResponseGetERPSupplierCreditsT,
  APIResponseGetERPVendorsT,
  APIResponseGetFinancialAccountsT,
  APIResponseGetOrganizationalEntitiesT,
  APIResponseUpdateOrganizationalEntityT,
  ManualERPConnectionPayload,
} from "./types";

export const createERPVendor = async (
  body: APIRequestCreateCompanyERPVendorT
) => {
  return fetchWithAuth(`/company/erp/vendors`, {
    method: "POST",
    body: JSON.stringify(body),
  });
};

export const createERPCustomer = async (
  body: APIRequestCreateCompanyERPCustomerT
) => {
  return fetchWithAuth(`/company/erp/customers`, {
    method: "POST",
    body: JSON.stringify(body),
  });
};

export const getERPVendors = async (): Promise<APIResponseGetERPVendorsT> => {
  return fetchWithAuth(`/company/erp/vendors`, {
    method: "GET",
  });
};

export const getERPCustomers =
  async (): Promise<APIResponseGetERPCustomersT> => {
    return fetchWithAuth(`/company/erp/customers`, {
      method: "GET",
    });
  };

export const syncReleaseFundsEntries = async (props: {
  id: number;
  type?: "seller" | "buyer";
}): Promise<APIResponse> => {
  const { id, type = "buyer" } = props;
  return fetchWithAuth(`/invoices/${id}/erp/release-funds`, {
    method: "POST",
    body: JSON.stringify({
      type,
    }),
  });
};

export const syncRepaymentEntries = async (props: {
  id: number;
  type?: "seller" | "buyer";
}): Promise<APIResponse> => {
  const { id, type = "buyer" } = props;
  return fetchWithAuth(`/invoices/${id}/erp/repayment`, {
    method: "POST",
    body: JSON.stringify({
      type,
    }),
  });
};

export const createERPGLAccount = async (
  body: APIRequestCreateCompanyERPGLAccountT
) => {
  return fetchWithAuth(`/company/erp/accounts`, {
    method: "POST",
    body: JSON.stringify(body),
  });
};

export const getERPConnectionStatus =
  async (): Promise<APIResponseGetERPConnectionStatusT> => {
    return fetchWithAuth(`/company/erp/connection`, {
      method: "GET",
    });
  };

export const getERPSupplierCredits = async (
  billId: number
): Promise<APIResponseGetERPSupplierCreditsT> => {
  return fetchWithAuth(
    `/company/erp/supplierCredits?${billId ? `&billId=${billId}` : ""}`,
    {
      method: "GET",
    }
  );
};

export const getERPFinancialAccounts =
  async (): Promise<APIResponseGetFinancialAccountsT> => {
    return fetchWithAuth(`/company/erp/financialAccounts`, {
      method: "GET",
    });
  };

export const deleteConnection = async (id: number): Promise<APIResponse> => {
  return fetchWithAuth(`/company/connection/${id}`, {
    method: "DELETE",
  });
};

export const getOrganizationalEntities =
  async (): Promise<APIResponseGetOrganizationalEntitiesT> => {
    return fetchWithAuth(`/company/erp/organizationalEntities`, {
      method: "GET",
    });
  };

export const updateOrganizationalEntity = async (
  id: number,
  payload: Partial<
    Omit<
      OrganizationalEntityT,
      "id" | "company_id" | "connection_id" | "erp_id"
    >
  >
): Promise<APIResponseUpdateOrganizationalEntityT> => {
  return fetchWithAuth(`/company/organizationalEntities/${id}`, {
    method: "PUT",
    body: JSON.stringify(payload),
  });
};

export const updateEntityConfiguration = async (payload: {
  entity_ids: number[];
  default_to_top_level: boolean;
}) => {
  return fetchWithAuth(`/company/erp/entities/enable`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

export const createManualERPConnection = async (
  manualConnectionPayload: ManualERPConnectionPayload
) => {
  const { type, ...payload } = manualConnectionPayload;
  return fetchWithAuth(`/company/connections/${type}`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};
