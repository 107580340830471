import { EntriesStatusT } from "../types";

export const getSellerRepaymentState = (
  invoice: InvoiceType,
  entity: OrganizationalEntityT | null,
  connection?: ConnectionT
): EntriesStatusT => {
  if (connection?.connection_type === "xero") {
    return {
      state: "disabled",
      message: "Xero integration is not supported at the moment",
    };
  }

  if (invoice?.seller_erp_repayment_entries_state === "in-progress") {
    return {
      state: "in-progress",
      message: "Repayment entries are being processed",
    };
  }

  if (invoice?.seller_erp_repayment_entries_state === "complete") {
    return {
      state: "complete",
      message: "Complete",
    };
  }

  if (invoice?.seller_erp_early_pay_entries_state !== "complete") {
    return {
      state: "disabled",
      message: "Early payment entries have not been pushed yet",
    };
  }

  if (!entity?.default_erp_bank_account_invoices) {
    return {
      state: "disabled",
      message:
        "Early payment bank account not set up. Please check the integration settings page",
    };
  }

  if (invoice?.status !== "paid-final") {
    return {
      state: "disabled",
      message: "Invoice has not been repaid yet",
    };
  }

  return { state: "ready" };
};
