import { ReactElement } from "react";
import Button from "../buttons/button";
import Modal from "./modal";

type PropsT = {
  open: boolean;
  setOpen: (val: boolean) => void;
  secondaryLabel?: string;
  primaryLabel?: string;
  handleSave?: () => void;
  handleClose?: () => void;
  title?: string;
  subtitle?: string | ReactElement;
  loading?: boolean;
  secondaryLoading?: boolean;
  hideCloseButton?: boolean;
  disableOverlayClose?: boolean;
};

export default function ConfirmModal(props: PropsT) {
  const {
    open,
    setOpen,
    handleSave,
    handleClose,
    primaryLabel = "",
    title = "",
    subtitle = "",
    secondaryLabel = "",
    loading,
    secondaryLoading,
    hideCloseButton,
    disableOverlayClose,
  } = props;

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={title}
      data-testid="confirm-modal"
      hideCloseButton={hideCloseButton}
      disableOverlayClose={disableOverlayClose}
    >
      <div className="px-4 pb-4 sm:max-w-lg md:px-6 md:pb-5">
        {subtitle && (
          <div className="pt-4 md:pt-5">
            <p className="text-base text-gray-500">{subtitle}</p>
          </div>
        )}

        <div className="flex flex-row-reverse justify-start gap-4 pt-4 md:pt-5">
          <Button
            onClick={handleSave}
            autoFocus
            label={primaryLabel || "Continue"}
            loading={loading}
            color="dark"
            data-testid="primary-button"
          />
          <Button
            onClick={handleClose}
            label={secondaryLabel || "Cancel"}
            color="white"
            outline
            loading={secondaryLoading}
            data-testid="secondary-button"
          />
        </div>
      </div>
    </Modal>
  );
}
