import { MouseEvent, ReactNode } from "react";
import Icon from "../icon/Icon";

export interface IBadgeProps {
  icon?: string;
  label?: string | ReactNode;
  color?:
    | "gray"
    | "yellow"
    | "green"
    | "indigo"
    | "teal"
    | "purple"
    | "number"
    | "red";
  type?: "number" | "button"; // Number, else it makes a badge like in invoicecards
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export default function Badge(props: IBadgeProps) {
  const { icon, onClick, label, color, type } = props;

  let textColor = "";
  let backgroundColor = "";
  let iconColor = "";

  switch (color) {
    case "gray": // info
      textColor = "text-gray-900";
      backgroundColor = "bg-gray-100";
      iconColor = "fill-gray-800";
      break;
    case "yellow": // pending
      textColor = "text-yellow-800";
      backgroundColor = "bg-yellow-100";
      iconColor = "fill-yellow-800";
      break;
    case "green": // success
      textColor = "text-green-800";
      backgroundColor = "bg-green-100";
      iconColor = "fill-green-800";
      break;
    case "indigo":
      textColor = "text-indigo-800";
      backgroundColor = "bg-indigo-100";
      iconColor = "fill-indigo-800";
      break;
    case "teal":
      textColor = "text-teal-900";
      backgroundColor = "bg-teal-100";
      iconColor = "fill-teal-900";
      break;
    case "purple":
      textColor = "text-purple-800";
      backgroundColor = "bg-purple-100";
      iconColor = "fill-purple-800";
      break;
    case "number":
      textColor = "text-white";
      backgroundColor = "bg-red-600";
      iconColor = "fill-red-800";
      break;
    case "red":
      textColor = "text-red-800";
      backgroundColor = "bg-red-100";
      iconColor = "fill-red-800";
      break;
    default:
      textColor = "text-red-800";
      backgroundColor = "bg-red-100";
      iconColor = "fill-red-800";
  }

  return (
    <>
      {type === "number" ? (
        <div
          className={`Badge flex ${label ? "h-5 w-5" : "h-4 w-4"} items-center justify-center rounded-full ${backgroundColor}`}
          data-testid="badge-number"
        >
          <span className={`text-xs ${textColor}`}>{label}</span>
          <span className="sr-only">{label}</span>
        </div>
      ) : type === "button" ? (
        <button
          className={`Badge flex w-fit items-center space-x-1 rounded-md px-2.5 py-1 hover:shadow ${backgroundColor}`}
          onClick={onClick}
          data-testid="badge-button"
        >
          <span
            className={`max-w-[200px] truncate whitespace-nowrap text-xs font-medium ${textColor}`}
          >
            {label}
          </span>
          <span className="sr-only">{label}</span>
          <Icon icon="x" size="13" iconStyle={`${iconColor}`} />
        </button>
      ) : (
        <div
          className={`Badge flex h-[22px] w-fit items-center rounded-md px-2.5 py-0.5 ${backgroundColor}`}
          data-testid={label || "badge"}
        >
          {icon && (
            <Icon icon={icon} iconStyle={`${iconColor} mr-1`} size="13" />
          )}
          <span
            className={`whitespace-nowrap text-xs font-medium ${textColor}`}
            onClick={onClick}
          >
            {label}
          </span>
        </div>
      )}
    </>
  );
}
