import clsx from "clsx";
import { ReactNode } from "react";
import { ToggleInput } from "../../../shared/components";

type PropsT = {
  isActive: boolean;
  isAutoSync: boolean;
  setAutoSync: (value: boolean) => void;
  title: string;
  description: string;
  toggleLabel: string;
  children?: ReactNode;
  isSetup?: boolean;
};

const IntegrationSettingsPanel = ({
  isActive,
  isAutoSync,
  setAutoSync,
  title,
  description,
  toggleLabel,
  children,
  isSetup,
}: PropsT) => {
  return (
    <div className="group flex w-full flex-col gap-4 rounded-xl border border-gray-200 bg-white px-4 py-5">
      <div
        className={clsx("flex gap-4", {
          "flex-col-reverse": isSetup,
          "flex-col-reverse items-start sm:flex-row sm:justify-between":
            !isSetup,
        })}
      >
        <div>
          <div className="font-semibold text-gray-900">{title}</div>
          <div className="text-sm font-normal text-gray-500">{description}</div>
        </div>

        <ToggleInput
          enabled={isActive ? isAutoSync : false}
          size="sm"
          color="green"
          onChange={setAutoSync}
          disabled={!isActive}
          label={toggleLabel}
        />
      </div>
      {children}
    </div>
  );
};

export default IntegrationSettingsPanel;
