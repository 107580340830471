import clsx from "clsx";

import { useAuth } from "../../../../state/AuthContexts";
import Avatar from "../avatar/avatar";
import Button from "../buttons/button";
import ToggleInput from "../input/toggleInput";

export interface IUserTableProps {
  teammates: (ContactType | UserType)[];
  handleChangeRole: (val: UserType) => void;
  handleDisableTeammate: (val: UserType) => void;
  handleRemoveTeammate: (val: UserType | ContactType) => void;
  handleRemoveContact: (val: UserType | ContactType) => void;
  deleteLoading?: number | null;
}

export default function UserTable(props: IUserTableProps) {
  const { user } = useAuth();
  const {
    teammates,
    handleDisableTeammate,
    handleRemoveTeammate,
    handleRemoveContact,
    deleteLoading,
  } = props;

  const renderListItem = (teammate: ContactType | UserType) => {
    // a signed up user of quickly
    if ("roles" in teammate) {
      const isCurrentUser = teammate.id === user?.id;
      const isOwner = (teammate?.roles || []).includes("owner");
      const disableTeammate = isCurrentUser || isOwner;

      return (
        <div className="flex w-full items-center justify-between space-x-4">
          <ToggleInput
            enabled={teammate?.enabled}
            disabled={disableTeammate}
            onChange={(val: boolean) => {
              handleDisableTeammate({ ...teammate, enabled: val });
            }}
          />
          <div className="hidden sm:block">
            <Avatar user={teammate} />
          </div>
          <div className="flex-1 truncate">
            <div
              className={clsx("truncate text-sm font-semibold ", {
                "text-gray-500": !teammate?.enabled,
                "text-gray-900": teammate?.enabled,
              })}
            >{`${teammate.first_name} ${teammate.last_name}`}</div>
            <div
              className={clsx("truncate text-sm", {
                "text-gray-500": !teammate?.enabled,
                "text-gray-900": teammate?.enabled,
              })}
            >
              {teammate.email}
            </div>
          </div>
          <div className="flex-shrink-0">
            {!disableTeammate && (
              <Button
                color="transparent"
                icon="trash"
                onClick={() => handleRemoveTeammate(teammate)}
                customIconSize="20"
                disabled={deleteLoading !== null}
                loading={Boolean(deleteLoading === teammate.id)}
              />
            )}
          </div>
        </div>
      );
    } else {
      // For pending invited users
      return (
        <>
          <div className="flex w-full items-center justify-between space-x-4">
            <ToggleInput disabled enabled={true} onChange={() => {}} />
            <div className="hidden sm:block">
              <Avatar user={teammate} />
            </div>
            <div className="flex-1 truncate">
              {teammate.first_name && (
                <div className="truncate text-sm font-bold text-gray-900">{`${teammate.first_name} ${teammate.last_name}`}</div>
              )}
              <div className="truncate text-sm text-gray-900">
                {teammate.email}
              </div>
            </div>

            <div className="flex flex-shrink-0 flex-row items-center gap-x-4">
              <div className="hidden text-sm text-gray-900 sm:block">
                Invite pending
              </div>
              <Button
                color="transparent"
                icon="trash"
                onClick={() => handleRemoveContact(teammate)}
                customIconSize="20"
                disabled={deleteLoading !== null}
                loading={Boolean(deleteLoading === teammate.id)}
              />
            </div>
          </div>
          <div className="mt-2 pl-14 text-sm text-gray-900 sm:hidden">
            Invite pending
          </div>
        </>
      );
    }
  };

  return (
    <div className="UserTable max-w-[630px] overflow-hidden rounded-lg border border-gray-200 bg-white">
      <ul role="list" className="divide-y-2 divide-gray-200">
        {teammates.length > 0 ? (
          teammates?.map((teammate, index) => (
            <li
              key={`${index}+${teammate.id}`}
              className="flex flex-col justify-between px-6 py-4 md:flex-row"
            >
              {renderListItem(teammate)}
            </li>
          ))
        ) : (
          <li className="py-6">
            <div className="text-center font-gilroy text-xl font-bold text-gray-900">
              Loading..
            </div>
          </li>
        )}
      </ul>
    </div>
  );
}
