import * as FullStory from "@fullstory/browser";
import { motion } from "framer-motion";
import type { AppProps } from "next/app";
import { useEffect } from "react";
import { DelayBanner, Layout } from "../src/shared/components";
import { AuthProvider } from "../state/AuthContexts";
import "../styles/globals.css";

export default function App({ Component, pageProps, router }: AppProps) {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_BUILD_ENV === "production") {
      FullStory.init({
        orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID || "",
        devMode: process.env.NEXT_PUBLIC_BUILD_ENV !== "production",
      });
    }

    if (process.env.NEXT_PUBLIC_BUILD_ENV !== "local") {
      // Intercom Setup
      (function () {
        const w = window;
        const ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          const d = document;
          const i = function () {
            // eslint-disable-next-line prefer-rest-params
            i.c(arguments);
          };
          // @ts-expect-error: intercom
          i.q = [];
          // @ts-expect-error: intercom
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          const l = function () {
            const s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src =
              "https://widget.intercom.io/widget/" +
              process.env.NEXT_PUBLIC_INTERCOM_ID;
            const x = d.getElementsByTagName("script")[0];
            // @ts-expect-error: intercom
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === "complete") {
            l();
            // @ts-expect-error: intercom
          } else if (w.attachEvent) {
            // @ts-expect-error: intercom
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    }

    // comment
    window &&
      window.Intercom &&
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: process.env.NEXT_PUBLIC_INTERCOM_ID,
      });

    const width = window.innerWidth;
    const isMobile = 700 > width;

    window &&
      window.Intercom &&
      window?.Intercom("update", {
        hide_default_launcher:
          process.env.NEXT_PUBLIC_BUILD_ENV !== "production" || isMobile,
      });
  }, []);

  return (
    <>
      <AuthProvider>
        <Layout>
          <motion.div
            key={router.route}
            data-testid="app-root"
            initial="initial"
            animate="animate"
            variants={{
              initial: {
                opacity: 0,
              },
              animate: {
                opacity: 1,
              },
            }}
          >
            <Component {...pageProps} />
            <DelayBanner />
          </motion.div>
        </Layout>
      </AuthProvider>
    </>
  );
}
