import { EntriesStatusT } from "../types";

export const getBuyerRepaymentState = (
  invoice: InvoiceType,
  entity: OrganizationalEntityT | null
): EntriesStatusT => {
  if (
    invoice?.buyer_erp_repayment_entries_state &&
    invoice?.buyer_erp_repayment_entries_state !== "ready"
  ) {
    let message;

    if (invoice?.buyer_erp_repayment_entries_state === "in-progress") {
      message = "Processing";
    }
    if (invoice?.buyer_erp_repayment_entries_state === "complete") {
      message = "Complete";
    }

    return { state: invoice.buyer_erp_repayment_entries_state, message };
  }

  if (invoice?.status !== "paid-final") {
    return {
      state: "disabled",
      message: "Bill has not been repaid yet",
    };
  }

  if (
    !entity?.default_erp_bank_account_bills &&
    !entity?.default_erp_credit_card_bills_erp_id
  ) {
    return {
      state: "disabled",
      message:
        "Default bank account or credit card not set up. Please check the integration settings page",
    };
  }

  if (
    invoice?.totals.discounts > 0 &&
    (!entity?.default_discounts_account_erp_id ||
      !entity.record_to_bill_account)
  ) {
    return {
      state: "disabled",
      message:
        "Trade discounts account not set up. Please check the integration settings page",
    };
  }

  if (!entity?.bill_clearing_account_erp_id) {
    return {
      state: "disabled",
      message:
        "Bill clearing account not set up. Please check the integration settings page",
    };
  }

  if (!invoice?.buyer_erp_repayment_entries_state) return { state: "ready" };

  return { state: "ready" };
};
