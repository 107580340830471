import { Switch } from "@headlessui/react";
import clsx from "clsx";
import Tooltip from "../tooltip";

type PropsT = {
  enabled: boolean | undefined;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  disabledReason?: string;
  label?: string;
  description?: string;
  color?: "black" | "green";
  loading?: boolean;
  size?: "sm" | "base";
  "data-testid"?: string;
};

export default function ToggleInput(props: PropsT) {
  const {
    enabled,
    onChange,
    disabled,
    disabledReason,
    color = "black",
    label,
    description,
    loading,
    size,
    "data-testid": dataTestId = "toggle-input",
  } = props;

  let enabledClass = "bg-gray-900";
  let disabledClass = "bg-gray-500";

  switch (color) {
    case "black":
      enabledClass = "bg-gray-900";
      disabledClass = "bg-gray-500";
      break;
    case "green":
      enabledClass = "bg-emerald-400";
      disabledClass = "bg-gray-500";
      break;
    default:
      // default black
      enabledClass = "bg-gray-900";
      disabledClass = "bg-gray-500";
      break;
  }

  const switchComponent = () => (
    <Switch.Group>
      <div className={clsx("flex flex-row")}>
        <Switch
          checked={enabled}
          onChange={onChange}
          disabled={disabled}
          data-testid={dataTestId}
          className={clsx(
            "relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none disabled:cursor-not-allowed disabled:bg-gray-200",
            {
              [enabledClass]: enabled,
              [disabledClass]: !enabled || loading,
              "animate-pulse": loading,
              "mt-1": label && size !== "sm",
            }
          )}
        >
          <span className="sr-only">{label || "Toggle input"}</span>

          <span
            aria-hidden="true"
            className={clsx(
              enabled ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow-sm ring-0 transition duration-200 ease-in-out"
            )}
          />
        </Switch>

        {label ? (
          <Switch.Label
            className={clsx(
              "ml-2 flex flex-col text-base font-medium text-gray-900 hover:cursor-pointer disabled:cursor-default",
              {
                "text-base font-medium": !size || size === "base",
                "text-sm font-medium": size === "sm",
                "pointer-events-none !text-gray-500": disabled,
              }
            )}
          >
            {label}

            {description && (
              <span className="text-sm font-normal text-gray-500">
                {description}
              </span>
            )}
          </Switch.Label>
        ) : null}
      </div>
    </Switch.Group>
  );

  const disabledSwitchComponent = () => (
    <Tooltip position="top" title={disabledReason}>
      {switchComponent()}
    </Tooltip>
  );
  return (
    <div className="flex flex-col">
      {disabled && disabledReason
        ? disabledSwitchComponent()
        : switchComponent()}
    </div>
  );
}
