import React from "react";

const IncreaseTouchTarget = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      {children}
      <span
        className="absolute left-1/2 top-1/2 size-[max(100%,2.75rem)] -translate-x-1/2 -translate-y-1/2 [@media(pointer:fine)]:hidden"
        aria-hidden="true"
        data-testid="increase-touch-target"
      />
    </>
  );
};

export default IncreaseTouchTarget;
